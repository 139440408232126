import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled, { css } from '@xstyled/styled-components'


const BtnStyle = css`
  display: block;
  width: ${p => p.fullwidth ? `100%` : `fit-content`};
  margin: ${p => p.center ? '0 auto' : '0'};
  padding: 30rpx 30rpx;
  font-size: 18rpx;
  font-weight: 800;
  text-align: center;
  color: var(--ssy-white);
  text-decoration: none;
  background-color: var(--ssy-orange);
  border: 0;
  box-sizing: border-box;
  transition: background-color 0.15s ease-in-out;
  
  &:hover {
    background-color: var(--ssy-black);
    cursor: pointer;
  }

  svg {
    width: 15rpx;
    height: 15rpx;
    margin-left: 10rpx;

    path {
      fill: var(--ssy-white)
    }
  }
`

const StyledButton = styled.button`
  ${BtnStyle}
`
const StyledLink = styled(Link)`
  ${BtnStyle}
`
const StyledExternalLink = styled.a`
  ${BtnStyle}
`
const InnerWrap = styled.div``

const Button = ({
  ariaLabel, className, center, fullWidth,
  icon, onClick, text, type, url, urlExt }) => {
  if (url) {
    return (
      <StyledLink
        center={center}
        className={`btn ${className}`}
        fullwidth={fullWidth ? 1 : 0}
        onClick={e => onClick(e)}
        to={url}
        >
        <InnerWrap>{text}{icon}</InnerWrap>
      </StyledLink>
    )
  }

  if (urlExt) {
    return (
      <StyledExternalLink
        center={center}
        className={`btn ${className}`}
        fullwidth={fullWidth ? 1 : 0}
        href={urlExt}
        rel="noopener"
        onClick={e => onClick(e)}
        target="_blank"
        >
        <InnerWrap>{text}{icon}</InnerWrap>
      </StyledExternalLink>
    )
  }

  return (
    <StyledButton
      aria-label={ariaLabel}
      center={center}
      className={`btn ${className}`}
      fullwidth={fullWidth ? 1 : 0}
      onClick={e => onClick(e)}
      type={type}
      >
      <InnerWrap>{text}{icon}</InnerWrap>
    </StyledButton>
  )
}

export default Button

Button.defaultProps = {
  ariaLabel: '',
  fullWidth: false,
  icon: '',
  onClick: () => {},
  url: '',
  urlExt: '',
  type: 'button',
}

Button.propTypes = {
  ariaLabel: PropTypes.string,
  fullWidth: PropTypes.bool,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  url: PropTypes.string,
  urlExt: PropTypes.string,
  type: PropTypes.string,
}