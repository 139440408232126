import React from 'react'
import styled, { css, up } from '@xstyled/styled-components'

import LinkIcon from '../link-icon'

const StyledInfoCard = styled.button`
  display: block;
  width: 100%;
  height: 100%;
  padding: 40rpx;
  background-color: var(--ssy-white);
  border: 0;
  text-align: left;
  /* overwrite chrome useragent style */
  font: 800 var(--font-gotham);

  h5 {
    margin-bottom: 10rpx;
  }

  &:hover {
    cursor: pointer;

    .link-icon {
      color: var(--ssy-black);

      .inner-wrapper {
        border-color: transparent;
      }

      .icon-arrow-right {
        g {
          fill: var(--ssy-black);
        }
      }
    }
  }

  ${
    up(
      'md',
      css`
        padding: 50rpx 60rpx;
      `
    )
  }

  ${up(
    'lg',
    css`
      border-top: 12px solid var(--ssy-orange);
    `
  )}
`

const InfoCard = ({ linkText, index, title }) => {
  return (
    <StyledInfoCard
      type="button"
      aria-label="display modal"
      className="info-card"
      data-modal-id={`next-step-${index}`}
      >
      <h5>{title}</h5>
      <LinkIcon
        text={linkText}
        staticLink
        />
    </StyledInfoCard>
  )
}

export default InfoCard