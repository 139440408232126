import React from 'react'
import ParagraphFullWidthImage from './paragraphs/paragraphFullWidthImage'
import DocumentCard from './paragraphs/document-card'
import ImageGridPopOut from './paragraphs/ImageGridPopOut'

const ParagraphsResolver = (data) => {
  const components = []

  data.forEach(({ entity }) => {
    components.push(resolve(entity))
  })

  return components
}

export default ParagraphsResolver

export const resolve = (entity) => {
  if (entity.__typename && entity.__typename.includes("Drupal_ParagraphFullWidthImage")) {
    return <ParagraphFullWidthImage key={entity.uuid} alt={entity.fieldFullWidthImage.alt} src={entity.fieldFullWidthImage.urlSharp.childImageSharp.gatsbyImageData} />;
  }

  if (entity.__typename && entity.__typename.includes(`Drupal_ParagraphBodyText`)) {
    const text = entity.fieldBody ? <div key={entity.uuid} dangerouslySetInnerHTML={{__html: entity.fieldBody.processed}}/> : null
    return (
      text
    );
  }

  if (entity.__typename && entity.__typename.includes(`Drupal_ParagraphImageCollection`)) {
    return <ImageGridPopOut key={entity.uuid} className='class' images={entity.fieldImage}/>
  }

  if (entity.entityTranslation.__typename.includes(`Drupal_ParagraphFileDescription`)) {
    const props = {
      files: entity.entityTranslation.fieldDownloadableFile ? entity.entityTranslation.fieldDownloadableFile : [],
      fileName: entity.entityTranslation.fieldDownloadableFile[0] ? entity.entityTranslation.fieldDownloadableFile[0].entity.filename : null,
      description: entity.entityTranslation.fieldFileDescription ? entity.entityTranslation.fieldFileDescription.processed : null,
      key: entity.entityTranslation.uuid ? entity.entityTranslation.uuid : null,
    };
    return (
      <DocumentCard {...props}/>
        )
  }
}
