import React from 'react'
import styled, { css, up } from '@xstyled/styled-components'
import anime from 'animejs'
import Slider from 'react-slick'
import ReactImageMagnify from '@blacklab/react-image-magnify'
import { getSrc } from 'gatsby-plugin-image'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import IconClose from '../../svgs/icons/icon-close.svg'

const StyledModal = styled.div`
  visibility: hidden;
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const BtnClose = styled.button`
  position: fixed;
  width: 40rpx;
  height: 40rpx;
  top: 10rpx;
  right: 0;
  background-color: transparent;
  border: 0;

  svg > g > g {
    transition: fill 0.5s ease-in-out;
  }

  &:hover {
    cursor: pointer;

    svg > g > g {
      fill: var(--ssy-black);
    }
  }
`

const SliderWrapper = styled.div`
  display: flex;
  width: ${p => p.fullWidth ? '100vw' : '80vw'};
  height: 90vh;
  margin: 5vh auto;
  align-items: center;
  justify-content: center;

  .slick-slider {
    width: 100%;
    margin: auto;

    .slick-list {
      height: auto !important;
    }

    ${up(
      'lg',
      css`
        width: ${p => p.fullWidth ? '100%' : '900px'};
      `
    )}

  ${up(
      'xl',
      css`
        width: ${p => p.fullWidth ? '100%' : '1100px'};
      `
    )}
  }

  .slick-slide {
    & > div {
      width: fit-content;
      margin: auto;
    }
  }
`

const ImgWrapper = styled.div``

const ModalImage = ({ alt, image, id, fullWidth }) => {

  const closeModal = () => {
    const closeModalAnime = anime.timeline({
      easing: 'easeInOutQuad',
      duration: 300,
      autoplay: false
    })

    closeModalAnime
      .add({
        targets: `#${id}`,
        scale: [1, 0],
      })
      .add({
        targets: `.modal-overlay`,
        backgroundColor: ['rgba(0, 0, 0, 0.7)', 'rgba(0, 0, 0, 0)'],
        complete: function () {
          const scrollY = document.body.style.top
          const overlay = document.querySelector('.modal-overlay')
          const modal = document.getElementById(id)

          document.body.style.position = ''
          document.body.style.width =''
          window.scrollTo(0, parseInt(scrollY || '0') * -1)
          overlay.style.display = ''
          overlay.style.zIndex = ''
          modal.style.visibility = ''
          modal.style.zIndex = ''
        }
      })

    closeModalAnime.play()
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <StyledModal
      id={id}
      >
      <BtnClose
        aria-label="close modal"
        onClick={() => { closeModal() }}
        >
        <IconClose />
      </BtnClose>
      <SliderWrapper fullWidth={fullWidth}>
        <Slider {...settings}>
          {(Array.isArray(image) ? image.map((img, index) => (
            <ImgWrapper key={index}>
                <ReactImageMagnify {...{
                  portalProps: { placement: 'over'},
                  isEnabled: true,
                  activationInteractionHint: 'hover',
                  activationInteractionMouse: 'hover',
                  imageProps: {
                      alt: img.alt,
                      isFluidWidth: true,
                      src: getSrc(img.urlSharp.childImageSharp.gatsbyImageData),
                  },
                  magnifiedImageProps: {
                      src: getSrc(img.urlSharp.childImageSharp.large),
                      width: img.width,
                      height: img.height,
                  }
                }} />
            </ImgWrapper>
          )) : (
            <ImgWrapper>
              <ReactImageMagnify {...{
                portalProps: { placement: 'over'},
                isEnabled: true,
                activationInteractionHint: 'hover',
                activationInteractionMouse: 'hover',
                imageProps: {
                    alt: alt,
                    isFluidWidth: true,
                    src: getSrc(image.urlSharp.childImageSharp.gatsbyImageData),
                },
                magnifiedImageProps: {
                    src: getSrc(image.urlSharp.childImageSharp.large),
                    width: image.width,
                    height: image.height,
                }
                }} />
            </ImgWrapper>
          ))}
        </Slider>
      </SliderWrapper>

    </StyledModal>
  );
}

export default ModalImage
