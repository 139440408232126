import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { Box, css, up } from '@xstyled/styled-components'
import _ from 'underscore'
import anime from 'animejs'

import Container from '../layout/container'
import PriorityDetail from './priority-detail'
import PriorityTitleSticky from './priority-title-sticky'

const StyledSection = styled.section`
  position: relative;

  ${up(
    'lg',
    css`
      background: linear-gradient(to right, var(--ssy-orange) 42.3vw, var(--ssy-white) 0);
      }
    `,
  )}
`

const SectionPriorities = ({ className, data, id, drupal, lang }) => {
  useEffect(() => {
    const setTitleHeight = _.debounce(() => {
      const titles = document.querySelectorAll('.desktop-inner-wrapper .priority .test-height')
      const desktopTitleWrapper = document.querySelector('.desktop-inner-wrapper')
      const titleHeights = []

      // push heights of all priority titles
      titles.forEach(title => {
        titleHeights.push(title.offsetHeight)
      })
      // set priority titles wrapper w/ max height
      if (desktopTitleWrapper) {
        desktopTitleWrapper.style.height = `${Math.max(...titleHeights)}px`
      }
    }, 300)


    setTitleHeight()

    window.addEventListener('resize', setTitleHeight)

    return () => {
      window.removeEventListener('resize', setTitleHeight)
    }
  }, [])

  let visibleSection = false
  const togglePriority = ((isVisible, section) => {

    if (isVisible && (visibleSection !== section)) {
      anime({
        targets: `.priority`,
        opacity: [0],
        duration: 200,
        easing: 'easeInOutQuad',
        complete: function () {
          anime({
            targets: `.${section}`,
            opacity: [0, 1],
            easing: 'easeInOutQuad',
            duration: 200,
            begin: function () {
              visibleSection = section
            }
          })
        }
      })
    }
  })

  return (
    <StyledSection id={id} className={className}>
        <PriorityTitleSticky data={drupal} lang={lang}/>
        <Container>
          <Box row  mx={-27.5} justifyContent="flex-end">
            <Box col={{ xs: 1, sm: 1, md: 1, lg: 7 / 12}} px={27.5}>
              {drupal.map((section, indexLevelOne) => (
                <PriorityDetail
                  key={section.entity.entityTranslation.uuid}
                  onChange={isVisible => togglePriority(isVisible,`priority-${indexLevelOne}-${lang}`)}
                  items={section.entity.entityTranslation.fieldTextAndImage}
                  data={data}
                  />
              ))}
            </Box>
          </Box>
        </Container>
    </StyledSection>
  )
}

export default SectionPriorities

SectionPriorities.defaultTypes = {
  className: 'section',
  title: '',
}

SectionPriorities.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
}
