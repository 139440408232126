import React from 'react'
import styled from '@xstyled/styled-components'

import { GatsbyImage } from "gatsby-plugin-image";

const StyledGrdItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  img {
    width: 100%;
    height: auto;
    border-bottom: 20px solid var(--ssy-orange);
  }
`

const GridItemContent = styled.div`
  padding: 20rpx 0;
  text-align: center;

  h5 {
    margin-bottom: 0;
  }
`

const ImageGridItem = ({ className, image, alt, title }) => {
  return (
    <StyledGrdItem className={className}>
      <GatsbyImage image={image} alt={alt} />
      <GridItemContent>
        <h5>{title}</h5>
      </GridItemContent>
    </StyledGrdItem>
  );
}

export default ImageGridItem
