import React from 'react'
import styled from '@xstyled/styled-components'


const StyledBtnTray = styled.div`
  position: sticky;
  width: fit-content;
  margin-bottom: 30rpx;
  bottom: 30rpx;
  left: 30rpx;
  z-index: 99;
`
const Link = styled.a`
  display: flex;

  height: 35rpx;
  padding: 0 20rpx;
  align-items: center;
  justify-content: center;
  color: var(--ssy-white);
  font-size: 12rpx;
  line-height: 12rpx;
  font-weight: 800;
  text-decoration: none;
  background-color: var(--ssy-orange);

  &:first-child {
    margin-bottom: 10rpx;
  }

  &:hover {
    background-color: var(--ssy-black);
  }
`

const BtnTray = ({ data}) => (
<StyledBtnTray className="btn-tray">
  <Link href="/documents" className="link">{data.cta.downloadPlan}</Link>
  <Link href="/documents" className="link">{data.cta.downloadFullPlan}</Link>
</StyledBtnTray>
)

export default BtnTray
