import React from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'
import styled, { Box } from '@xstyled/styled-components'
import anime from 'animejs'

import Button from '../button'
import Container from '../layout/container'
import IconArrowDown from  '../../svgs/icons/icon-arrow-down.svg'
import ImageModal from './image-modal'
import { GatsbyImage } from "gatsby-plugin-image";
import SectionInnerWrapper from  './section-inner-wrapper'
import parse from 'html-react-parser'

const StyledSection = styled.section`

  .image-modal {
    margin-bottom: 40rpx;
  }
`

const FileInfo = styled.div`
  padding-top: 6rpx;
  font-size: 14rpx;
  line-height: 30rpx;
  text-align: center;
  text-transform: uppercase;
`

const SectionTitle = styled(Box)`
  margin-bottom: 24rpx;
  text-align: center;
  opacity: 0;
`

const SectionDownload = ({ className, id, buttons, alt, image, data, title, lang }) => {

  const trimFileExtension = (string) => {
    const fileName = string.split('.')
    return `${fileName[1]}`
  }

  const formatBytes = (bytes, index, decimals = 2) => {
    if (index === 0 ) {
      return "315 MB";
    }
    else if (index === 1) {
      return "602 MB";
    }
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const sectionAnimation = (isVisible) => {
    let animeFinished = false

    if (isVisible && !animeFinished) {
      anime({
        targets: `#${id} .section-title`,
        opacity: [0, 1],
        easing: 'easeInOutQuad',
        complete: function () {
          animeFinished = true
        }
      })
    }
  }

  return (
    <StyledSection id={id} className={className}>
      <VisibilitySensor onChange={isVisible => sectionAnimation(isVisible)} partialVisibility>
        <SectionInnerWrapper>
          {title ? (
              <Container>
                <Box row>
                  <SectionTitle col={{xs: 1, sm: 1, md: 2 / 3}} mx="auto" className="section-title">
                    {parse(`${title}`)}
                  </SectionTitle>
                </Box>
              </Container>
            ) :
            ''
          }
          <ImageModal data={data} id={image.entity.uuid} fullWidth>
            <GatsbyImage image={image.urlSharp.childImageSharp.gatsbyImageData} alt={alt} />
          </ImageModal>
          <Container>
            <Box row m={-27.5} justifyContent="center" >
              {buttons.map((button, index) => (
                <Box
                  key={button.entity.entityTranslation.uuid}
                  col={{ xs: 1 , sm: 1, md: 1, lg: 1 / 2, xl: 5 / 12}}
                  p={27.5}
                >
                  <Button
                    ariaLabel={button.entity.entityTranslation.fieldButtonText}
                    text={button.entity.entityTranslation.fieldButtonText}
                    icon={<IconArrowDown />}
                    urlExt={`${process.env.GATSBY_DRUPAL_BASE_URL}/${button.entity.entityTranslation.fieldDownloadableFile[0].entity.uri.url}`}
                    fullWidth
                  />
                  <FileInfo>
                    {`.${trimFileExtension(button.entity.entityTranslation.fieldDownloadableFile[0].entity.filename)}, ${formatBytes(button.entity.entityTranslation.fieldDownloadableFile[0].entity.filesize, index)}`}
                  </FileInfo>
                </Box>
              ))}
            </Box>
          </Container>
        </SectionInnerWrapper>
      </VisibilitySensor>
    </StyledSection>
  );
}

export default SectionDownload

SectionDownload.defaultProps = {
  alt: '',
  className: 'section',
  image: '',
  title: ''
}

SectionDownload.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  title: PropTypes.string,
}
