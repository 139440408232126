import React from 'react'
import PropTypes from 'prop-types'
import styled, { Box, css, up, down } from '@xstyled/styled-components'
import VisibilitySensor from 'react-visibility-sensor'
import parse from 'html-react-parser'
import anime from 'animejs'

import Container from '../layout/container'
import TextBubble from './text-bubble'
import SectionInnerWrapper from './section-inner-wrapper'
import IconPark from '../../svgs/icons/icon-park.svg'
import IconStation from '../../svgs/icons/icon-station.svg'
import IconTools from '../../svgs/icons/icon-tools.svg'

const StyledSection = styled.section`
  text-align: center;

  .section-column {
    scale: 0.1;
    opacity: 0;
  }

  ${up(
    'lg',
    css`
      display: flex;
      align-items: center;
    `
  )}
`
const SectionTitle = styled(Box)`
  margin-bottom: 40rpx;

  h4 {
    margin-bottom: 16rpx;
  }

  h6 {
    margin-bottom: 16rpx;
  }

  ${down(
    'md',
    css`
      h2 {
        font-size: 24rpx;
        line-height: 30rpx;
      }
    `
  )}
`

const SectionSuperTitle = styled(Box)`
  font-size: 13rpx;
  line-height: 13rpx;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 16rpx;

  ${up(
    'md',
    css`
      font-size: 18rpx;
      line-height: 18rpx;
      margin-bottom: 40rpx;
    `
  )}
`

const SectionRow = styled(Box)`
  overflow: hidden;
  padding: 0rpx 0 0;

  ${up(
    'md',
    css`
      padding: 60rpx 0 0;
    `
  )}
`

const SectionColumn = styled(Box)`
  transform: translateY(80vh);
`
const SectionTextBubbles = ({
  className, id, items, title, superTitle,
  publicLandSection, regionalSection
}) => {
  let animeFinished = false

  const animateSection = (isVisible) => {
    if (isVisible && !animeFinished) {
      const animateColumns = anime.timeline({
        easing: 'easeOutElastic(1, .7)',
        duration: 1000,
        autoplay: false,
      })

      animateColumns
        .add({
          targets: `#${id} .section-column`,
          // translateY: ['80vh', 0],
          scale: [0.1, 1],
          opacity: [0, 1],
          delay: anime.stagger(300),
          complete: function () {
            animeFinished = true
          }
        })

      animateColumns.play()
    }
  }

  const publicLandIcons = [
    <IconStation />,
    <IconPark />,
    <IconTools />
  ]

  return (
    <StyledSection id={id} className={className}>
      <VisibilitySensor onChange={animateSection} partialVisibility>
      <SectionInnerWrapper>
        <Container>
            <SectionSuperTitle>{superTitle}</SectionSuperTitle>
            <Box row justifyContent="center">
              <SectionTitle col={{ xs: 1,  md: 2 / 3}} mx="auto" className="section-title">
                {parse(title)}
              </SectionTitle>
            </Box>
            <SectionRow row m={-27.5} justifyContent="center">
              {items.length === 4 ? items.map((item, i) => (
                <SectionColumn
                  key={item.entity.entityTranslation.uuid}
                  col={{xs: 1, sm: 1, md: 1 / 2, lg: 1 / 4}}
                  p={27.5}
                  className="section-column">
                  {item.entity.entityTranslation.fieldText ?
                    (<TextBubble
                      icon={publicLandSection ? publicLandIcons[i] : ''}
                      title={parse(item.entity.entityTranslation.fieldText.processed)}
                      />)
                    :
                    (<TextBubble
                      title={item.entity.fieldTitle}
                      subtitle={item.entity.fieldSubtitle}
                      />
                    )
                  }
                </SectionColumn>
              )) : ''}

              {items.length === 3 ? items.map((item, i) => (
                <SectionColumn
                  key={item.entity.entityTranslation.uuid}
                  col={regionalSection ? {xs: 1, sm: 1, md: 1 / 2, lg: 1 / 2, xl: 1 / 3} : {xs: 1, sm: 1, md: 1 / 2, lg: 1 / 3}}
                  p={27.5}
                  className="section-column">
                  {item.entity.entityTranslation.fieldText ?
                    (<TextBubble
                      icon={publicLandSection ? publicLandIcons[i] : ''}
                      title={parse(item.entity.entityTranslation.fieldText.processed)}
                      />)
                    :
                    (<TextBubble
                      title={item.entity.fieldTitle}
                      subtitle={item.entity.fieldSubtitle}
                      />
                    )
                  }
                </SectionColumn>
              )) : ''}

            </SectionRow>
          </Container>
      </SectionInnerWrapper>
      </VisibilitySensor>
    </StyledSection>
  )
}

export default SectionTextBubbles

SectionTextBubbles.defaultProps = {
  className: 'section',
  id: '',
  title: '',
  regionalSection: false,
  publicLandSection: false
}

SectionTextBubbles.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  regionalSection: PropTypes.bool,
  publicLandSection: PropTypes.bool
}
