import React, { useEffect, useState } from 'react'
import styled from '@xstyled/styled-components'
import anime from 'animejs'

import BtnTranslate from '../btn-translate'
import BtnNavMobile from '../btn-nav-mobile'
import BtnTray from './btn-tray'
import Modal from '../modal'
import ModalImage from './modal-image'
import Navigation from './navigation'
import PreFooter from '../layout/pre-footer'
import SectionDownload from './section-download'
import SectionIntro from './section-intro'
import SectionTextBubbles from './section-text-bubbles'
import SectionTextBubblesTwoRows from './section-text-bubbles-two-rows'
import SectionImageGrid from './section-image-grid'
import SectionImageTitle from './section-image-title'
import SectionImageText from './section-image-text'
import SectionInfoCards from './section-info-cards'
import SectionNewsEvents from './section-news-events'
import SectionPriorities from './section-priorities'
import SectionTitle from './section-title'
import SectionTitleText from './section-title-text'

const ModalOverlay = styled.div`
  position: fixed;
  display: none;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  z-index: -999;
`

const Home  = ({ drupal, lang, data, sharedVisionImage, planIntroImage, nextStepsIntroImage, prioritiesIntroImage }) => {
    const [showModal, setShowModal] = useState(false);


  useEffect(() => {
    setShowModal(true, []);
    const openModal = (id, scrollY, modalImage) => {

      const overlay = document.querySelector('.modal-overlay')
      const modal = document.getElementById(id)

        if (modalImage) {
          const openModalImageAnime = anime.timeline({
            easing: 'easeInOutQuad',
            autoplay: false,
            duration: 200
          })

          openModalImageAnime
            .add({
              targets: `.modal-overlay`,
              backgroundColor: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0.7)'],
              begin: function () {
                document.body.style.top = `-${scrollY}px`
                document.body.style.position = 'fixed'
                document.body.style.width ='100vw'
                overlay.style.display = 'block'
                overlay.style.zIndex = '99'
                modal.style.visibility = 'visible'
                modal.style.zIndex = '100'
              },
              complete: function () {
                document.addEventListener('click', (e) => {
                  const overlayClicked = overlay.contains(e.target)

                  if (overlayClicked) {
                    const closeModalImageAnime = anime.timeline({
                      easing: 'easeInOutQuad',
                      duration: 300,
                      autoplay: false
                    })

                    closeModalImageAnime
                      .add({
                        targets: `#${id}`,
                        scale: [1, 0]
                      })
                      .add({
                        targets: `.modal-overlay`,
                        backgroundColor: ['rgba(0, 0, 0, 0.7)', 'rgba(0, 0, 0, 0)'],
                        complete: function () {
                          const scrollY = document.body.style.top
                          const overlay = document.querySelector('.modal-overlay')
                          const modal = document.getElementById(id)

                          document.body.style.position = ''
                          document.body.style.width =''
                          window.scrollTo(0, parseInt(scrollY || '0') * -1)
                          overlay.style.display = ''
                          overlay.style.zIndex = ''
                          modal.style.visibility = ''
                          modal.style.zIndex = '-999'
                        }
                      })

                    closeModalImageAnime.play()
                  }
                })
              }
            })
            .add({
              targets: `#${id}`,
              scale: [0, 1]
            })

          openModalImageAnime.play()
        } else {

          const openModalAnime = anime.timeline({
            easing: 'easeInOutQuad',
            autoplay: false,
            duration: 200
          })

          openModalAnime
            .add({
              targets: `.modal-overlay`,
              backgroundColor: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0.7)'],
              begin: function () {
                document.body.style.top = `-${scrollY}px`
                document.body.style.position = 'fixed'
                document.body.style.width ='100vw'
                overlay.style.display = 'block'
                overlay.style.zIndex = '99'
                modal.style.visibility = 'visible'
                modal.style.zIndex = '100'
              },
              complete: function () {
                document.addEventListener('click', (e) => {
                  const overlayClicked = overlay.contains(e.target)

                  if (overlayClicked) {
                    const closeModalAnime = anime.timeline({
                      easing: 'easeInOutQuad',
                      duration: 300,
                      autoplay: false
                    })

                    closeModalAnime
                      .add({
                        targets: `#${id}`,
                        translateX: ['25vw', '100vw'],
                      })
                      .add({
                        targets: `.modal-overlay`,
                        backgroundColor: ['rgba(0, 0, 0, 0.7)', 'rgba(0, 0, 0, 0)'],
                        complete: function () {
                          const scrollY = document.body.style.top
                          const overlay = document.querySelector('.modal-overlay')
                          const modal = document.getElementById(id)

                          document.body.style.position = ''
                          document.body.style.width =''
                          window.scrollTo(0, parseInt(scrollY || '0') * -1)
                          overlay.style.display = ''
                          overlay.style.zIndex = ''
                          modal.style.visibility = ''
                          modal.style.zIndex = ''
                        }
                      })

                    closeModalAnime.play()
                  }
                })
              }
            })
            .add({
              targets: `#${id}`,
              translateX: ['100vw', '25vw'],
            })

          openModalAnime.play()
        }
    }

    const handleModal = () => {
      const buttons = document.querySelectorAll('button[data-modal-id]')
      buttons.forEach(button => {
        const modalId = button.getAttribute('data-modal-id')

        button.addEventListener('click', (e) => {
          openModal(modalId, window.scrollY)
        }, false)
      })
    }

    const handleModalImage = () => {
      const buttons = document.querySelectorAll('button[data-modal-image-id')

      buttons.forEach(button => {
        const modalId = button.getAttribute('data-modal-image-id')
        button.addEventListener('click', (e) => {
          openModal(modalId, window.scrollY, true)
        }, false)
      })
    }

    setTimeout(() => {
      handleModal()
      handleModalImage()
    }, 300)
  }, [])
  const sharedVision = drupal.sharedVision.entities[0].entityTranslation;
  const plan = drupal.plan.entities[0].entityTranslation;
  const priorities = drupal.priorities.entities[0].entityTranslation;
  const nextSteps = drupal.next_steps.entities[0].entityTranslation;
  return (
    <div>
      <BtnNavMobile data={data} lang={lang.lang} />
      <Navigation data={data} />
        <section id="section-a-shared-vision">
          <SectionIntro
            id="subsection-intro-a-shared-vision"
            img={sharedVisionImage}
            className="section"
            title={sharedVision.fieldIntroBubble.processed}
            regionalSection
            btnTranslate={<BtnTranslate lang={lang.lang} data={data} hideMobile />}
            sharedVision
          />
          <SectionTextBubbles
            className="section"
            id="subsection-diversity"
            items={sharedVision.fieldStatistics}
            title={sharedVision.fieldStatisticsTitle.processed}
            superTitle={sharedVision.fieldStatisticsSupertitle}
            regionalSection
          />
          <SectionImageText
            id="subsection-rapid-growth"
            className="section"
            text={sharedVision.fieldFirstTextImage.entity.entityTranslation.fieldText.processed}
            image={sharedVision.fieldFirstTextImage.entity.entityTranslation.fieldImage[0].urlSharp.childImageSharp.gatsbyImageData}
            imageId={sharedVision.fieldFirstTextImage.entity.entityTranslation.fieldImage[0].entity.uuid}
            alt={sharedVision.fieldFirstTextImage.entity.entityTranslation.fieldImage[0].alt}
            alignment="imageRight"
            data={data}
            imageModal
          />
          <SectionTitle
            id="subsection-next-generation"
            className="section"
            title={sharedVision.fieldFirstWysiwyg.processed}
          />
          <SectionImageGrid
            id="subsection-image-grid-steering-committee"
            className="section"
            title={sharedVision.fieldSecondWysiwyg.processed}
            items={sharedVision.fieldImageCollection.entity.entityTranslation.fieldImage}
          />
          <SectionImageText
            id="subsection-the-master-plan-is"
            className="section"
            text={sharedVision.fieldSecondTextImage.entity.entityTranslation.fieldText.processed}
            image={sharedVision.fieldSecondTextImage.entity.entityTranslation.fieldImage[0].urlSharp.childImageSharp.gatsbyImageData}
            imageId={sharedVision.fieldSecondTextImage.entity.entityTranslation.fieldImage[0].entity.uuid}
            alt={sharedVision.fieldSecondTextImage.alt}
            data={data}
            imageModal
          />
          <SectionImageText
            id="subsection-what-comes-first"
            className="section"
            text={sharedVision.fieldThirdTextImage.entity.entityTranslation.fieldText.processed}
            image={sharedVision.fieldThirdTextImage.entity.entityTranslation.fieldImage[0].urlSharp.childImageSharp.gatsbyImageData}
            imageId={sharedVision.fieldThirdTextImage.entity.entityTranslation.fieldImage[0].entity.uuid}
            alt={sharedVision.fieldThirdTextImage.alt}
            data={data}
            imageModal
          />
        </section>
        {/* The Plan */}
        <section id="section-the-plan">
          <SectionIntro
            id="subsection-intro-the-plan"
            className="section"
            title={plan.fieldIntroBubble.processed}
            lgText
            img={planIntroImage}
            />
          <SectionTextBubbles
            id="subsection-public-parks-plaza"
            className="section"
            items={plan.fieldTextBubble}
            title={plan.fieldFirstWysiwyg.processed}
            regionalSection
            />
          <SectionImageTitle
            id="subsection-flexible-deck-img"
            className="section"
            alt={plan.fieldFirstFullWidthImage.alt}
            image={plan.fieldFirstFullWidthImage}
            data={data}
            title={plan.fieldFirstFullWidthImageTit.processed}
            />
          <SectionImageTitle
            id="subsection-flexible-deck-img-2"
            className="section"
            alt={plan.fieldSecondFullWidthImage.alt}
            image={plan.fieldSecondFullWidthImage}
            title={plan.fieldSecondWysiwyg.processed}
            data={data}
            />
          <SectionTextBubbles
            id="subsection-public-land"
            className="section"
            items={plan.fieldPrimaryStatistics}
            title={plan.fieldInfoBubbleTitle.processed}
            publicLandSection
            />
          <SectionTextBubblesTwoRows
            className="section"
            id="subsection-stat-bubbles-two-rows"
            items={plan.fieldStatistics}
            // title={sharedVision.fieldStatisticsTitle.processed}
            superTitle={plan.fieldStatisticsSupertitle}
            regionalSection
            />
          <SectionDownload
            id="subsection-downloads"
            className="section"
            buttons={plan.fieldFileButton}
            alt={plan.fieldThirdFullWidthImage.alt}
            image={plan.fieldThirdFullWidthImage}
            data={data}
            title={plan.fieldThirdFullWidthImageTit.processed}
            lang={lang}
          />
        </section>
        {/* Priorities */}
        <section id="section-priorities">
          <SectionIntro
            id="subsection-intro-priorities"
            className="section"
            title={priorities.fieldIntroBubble.processed}
            lgText
            img={prioritiesIntroImage}
            />
          <SectionTitleText
            id="subsection-master-plan"
            className="section"
            title={priorities.fieldFirstWysiwyg.processed}
            items={priorities.fieldInteractives}
            />
          <SectionPriorities
            id="subsection-priority-details"
            className="section"
            title="Sunnyside Yard is an opportunity to proactively and comprehensively plan fro the next generation in Western Queens."
            drupal={priorities.fieldInteractives}
            lang={lang.lang}
            data={data}
            />
        </section>

        {/* Next Steps */}
        <section id="section-plan-becomes-reality">
          <SectionIntro
            id="subsection-plan-becomes-reality"
            className="section"
            title={nextSteps.fieldIntroBubble.processed}
            lgText
            img={nextStepsIntroImage}
            />
          <SectionTitleText
            id="subsection-what-comes-next"
            className="section"
            title={nextSteps.fieldFirstWysiwyg.processed}
            />
          <SectionImageText
            id="subsection-planning"
            className="section"
            text={nextSteps.fieldFirstTextImage.entity.entityTranslation.fieldText.processed}
            image={nextSteps.fieldFirstTextImage.entity.entityTranslation.fieldImage[0].urlSharp.childImageSharp.gatsbyImageData}
            imageId={nextSteps.fieldFirstTextImage.entity.entityTranslation.fieldImage[0].entity.uuid}
            alt={nextSteps.fieldFirstTextImage.entity.entityTranslation.fieldImage[0].alt}
            alignment="imageRight"
            data={data}
            imageModal
            />
          <SectionInfoCards
            id="subsection-info-cards"
            className="section"
            image={nextSteps.fieldThirdTextImage.entity.entityTranslation.fieldImage[0].urlSharp.childImageSharp.gatsbyImageData}
            imageId={nextSteps.fieldThirdTextImage.entity.entityTranslation.fieldImage[0].entity.uuid}
            text={nextSteps.fieldThirdTextImage.entity.entityTranslation.fieldText.processed}
            alt={nextSteps.fieldThirdTextImage.entity.entityTranslation.fieldImage[0].alt}
            items={nextSteps.fieldPopOut}
            />
        </section>
        {/* News & Events */}
        <section id="section-news-events">
          <SectionNewsEvents
            id="subsection-news-events"
            className="section"
            data={data.newsEvents}
            newsEventsData={drupal.newsEventsData}
            lang={lang.lang}
            />
        </section>

        {/* PreFooter */}
        <PreFooter
          className="section fp-auto-height"
          homepage
          />
        <BtnTray data={data} />
      {/* next steps modals */}
      {/* {nextSteps.fieldPopOut.map((modal, i) => (
        <Modal
          key={modal.entity.entityTranslation.uuid}
          title={modal.entity.entityTranslation.fieldTitle}
          id={`next-step-${i}`}
          content={modal.entity.entityTranslation.fieldModalContent}
          />
        )
      )} */}
      {showModal &&
      (<>
        <Modal
          key={nextSteps.fieldPopOut[0].entity.entityTranslation.uuid}
          title={nextSteps.fieldPopOut[0].entity.entityTranslation.fieldTitle}
          id={`next-step-${0}`}
          content={nextSteps.fieldPopOut[0].entity.entityTranslation.fieldModalContent}
        />

        <ModalImage
          image={plan.fieldFirstFullWidthImage}
          id={plan.fieldFirstFullWidthImage.entity.uuid}
          alt={plan.fieldFirstFullWidthImage.alt}
          fullWidth
        />
        <ModalImage
          image={plan.fieldSecondFullWidthImage}
          id={plan.fieldSecondFullWidthImage.entity.uuid}
          alt={plan.fieldSecondFullWidthImage.alt}
          fullWidth
        />
        <ModalImage
          image={plan.fieldThirdFullWidthImage}
          id={plan.fieldThirdFullWidthImage.entity.uuid}
          alt={plan.fieldThirdFullWidthImage.alt}
          fullWidth
        />

        <ModalImage
          image={sharedVision.fieldFirstTextImage.entity.entityTranslation.fieldImage[0]}
          id={sharedVision.fieldFirstTextImage.entity.entityTranslation.fieldImage[0].entity.uuid}
          alt={sharedVision.fieldFirstTextImage.entity.entityTranslation.fieldImage[0].alt}
        />

        <ModalImage
          image={sharedVision.fieldSecondTextImage.entity.entityTranslation.fieldImage[0]}
          id={sharedVision.fieldSecondTextImage.entity.entityTranslation.fieldImage[0].entity.uuid}
          alt={sharedVision.fieldSecondTextImage.entity.entityTranslation.fieldImage[0].alt}
        />

        <ModalImage
          image={sharedVision.fieldThirdTextImage.entity.entityTranslation.fieldImage[0]}
          id={sharedVision.fieldThirdTextImage.entity.entityTranslation.fieldImage[0].entity.uuid}
          alt={sharedVision.fieldThirdTextImage.entity.entityTranslation.fieldImage[0].alt}
        />

        <ModalImage
          image={nextSteps.fieldFirstTextImage.entity.entityTranslation.fieldImage[0]}
          id={nextSteps.fieldFirstTextImage.entity.entityTranslation.fieldImage[0].entity.uuid}
          alt={nextSteps.fieldFirstTextImage.entity.entityTranslation.fieldImage[0].alt}
        />
        <ModalImage
          image={nextSteps.fieldThirdTextImage.entity.entityTranslation.fieldImage[0]}
          id={nextSteps.fieldThirdTextImage.entity.entityTranslation.fieldImage[0].entity.uuid}
          alt={nextSteps.fieldThirdTextImage.entity.entityTranslation.fieldImage[0].alt}
        />

        {priorities.fieldInteractives.map(({entity}) => (
          <ModalImage
            key={entity.entityTranslation.fieldTextAndImage.entity.entityTranslation.uuid}
            id={entity.entityTranslation.fieldTextAndImage.entity.entityTranslation.uuid}
            image={entity.entityTranslation.fieldTextAndImage.entity.entityTranslation.fieldCoverImage}
          />
        ))}
        <ModalOverlay className="modal-overlay" />
      </>)
      }
    </div>
  );
}

export default Home
