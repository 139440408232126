import React from 'react'
import { graphql } from 'gatsby'

import Home from '../components/home/index'
import Layout from '../components/layout/layout'
import SEO from '../components/seo'

const Homepage = ({ data, pageContext }) => {
  const lowerLang = pageContext.lang.toLowerCase()
  let capLang = lowerLang.charAt(0).toUpperCase() + lowerLang.charAt(1)
  capLang = capLang === 'Zh' ? 'ZhHant' : capLang
  return (
    <Layout lang={pageContext} homepage>
      <SEO title="Home" />
      <Home
        data={data[`all${capLang}Json`].edges[0].node}
        drupal={data.drupal}
        lang={pageContext}
        sharedVisionImage={data.sharedVisionImage}
        planIntroImage={data.planIntroImage}
        nextStepsIntroImage={data.nextStepsIntroImage}
        prioritiesIntroImage={data.prioritiesIntroImage}
      />
    </Layout>
  )
}

export const query = graphql`query ($lang: Drupal_LanguageId!) {
  drupal {
    sharedVision: nodeQuery(
      filter: {conditions: [{operator: IN, field: "type", value: ["shared_vision"]}]}
    ) {
      entities {
        ... on Drupal_NodeSharedVision {
          entityTranslation(language: $lang) {
            ...NodeSharedVisionFragment
          }
        }
      }
    }
    plan: nodeQuery(
      filter: {conditions: [{operator: IN, field: "type", value: ["plan"]}]}
    ) {
      entities {
        entityTranslation(language: $lang) {
          ...NodePlanFragment
        }
      }
    }
    priorities: nodeQuery(
      filter: {conditions: [{operator: IN, field: "type", value: ["priorities"]}]}
    ) {
      entities {
        ... on Drupal_NodePriorities {
          entityTranslation(language: $lang) {
            ...NodePrioritiesFragment
          }
        }
      }
    }
    next_steps: nodeQuery(
      filter: {conditions: [{operator: IN, field: "type", value: ["next_steps"]}]}
    ) {
      entities {
        entityTranslation(language: $lang) {
          ...NodeNextStepsFragment
        }
      }
    }
    newsEventsData: nodeQuery(limit: 4, offset: 0,
      filter: {
        conditions: [{
          operator: EQUAL, field: "type", value: ["news_event"]
        }]
      }
      sort: [{
        field: "field_date",
        direction: DESC
      }]
    ) {
      entities {
        entityId,
        entityLabel,
        ... on Drupal_NodeNewsEvent {
          fieldType,
          fieldSlug,
          fieldDate {
            value
          },
          fieldExternalLink {
            title,
            url {
              path
            }
          },
          fieldCoverImage {
            url
          }
        }
      }
    }
  }
  nextStepsIntroImage: file(
    relativePath: {eq: "images/image-intro-shared-vision.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 85, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  prioritiesIntroImage: file(
    relativePath: {eq: "images/image-intro-shared-vision.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 85, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  sharedVisionImage: file(
    relativePath: {eq: "images/image-intro-shared-vision.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 85, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  planIntroImage: file(relativePath: {eq: "images/image-intro-the-plan.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 85, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  allEnJson {
    edges {
      node {
        cta {
          back
          learnMore
          downloadPlan
          downloadFullPlan
        }
        footerCopyright
        footerNav {
          link
          name
        }
        footerNewsLetter {
          placeHolder
          thankYou
          title
        }
        lightbox {
          cta {
            carousel
            enlarge
          }
        }
        mainNav {
          link
          name
        }
        newsEvents {
          cta {
            events
            news
            register
            viewAll
          }
          filter {
            all
            events
            news
            showing
          }
          title
          indexTitle
        }
        siteMetaData {
          author
          description
          title
        }
        translations {
          cta
          languages {
            link
            name
          }
        }
      }
    }
  }
  allEsJson {
    edges {
      node {
        cta {
          back
          learnMore
          downloadPlan
          downloadFullPlan
        }
        footerCopyright
        footerNav {
          link
          name
        }
        footerNewsLetter {
          placeHolder
          thankYou
          title
        }
        lightbox {
          cta {
            carousel
            enlarge
          }
        }
        mainNav {
          link
          name
        }
        newsEvents {
          cta {
            events
            news
            register
            viewAll
          }
          filter {
            all
            events
            news
            showing
          }
          title
          indexTitle
        }
        siteMetaData {
          author
          description
          title
        }
        translations {
          cta
          languages {
            link
            name
          }
        }
      }
    }
  }
  allZhHantJson {
    edges {
      node {
        cta {
          back
          learnMore
          downloadPlan
          downloadFullPlan
        }
        footerCopyright
        footerNav {
          link
          name
        }
        footerNewsLetter {
          placeHolder
          thankYou
          title
        }
        lightbox {
          cta {
            carousel
            enlarge
          }
        }
        mainNav {
          link
          name
        }
        newsEvents {
          cta {
            events
            news
            register
            viewAll
          }
          filter {
            all
            events
            news
            showing
          }
          title
          indexTitle
        }
        siteMetaData {
          author
          description
          title
        }
        translations {
          cta
          languages {
            link
            name
          }
        }
      }
    }
  }
  allKoJson {
    edges {
      node {
        cta {
          back
          learnMore
          downloadPlan
          downloadFullPlan
        }
        footerCopyright
        footerNav {
          link
          name
        }
        footerNewsLetter {
          placeHolder
          thankYou
          title
        }
        lightbox {
          cta {
            carousel
            enlarge
          }
        }
        mainNav {
          link
          name
        }
        newsEvents {
          cta {
            events
            news
            register
            viewAll
          }
          filter {
            all
            events
            news
            showing
          }
          title
          indexTitle
        }
        siteMetaData {
          author
          description
          title
        }
        translations {
          cta
          languages {
            link
            name
          }
        }
      }
    }
  }
  allArJson {
    edges {
      node {
        cta {
          back
          learnMore
          downloadPlan
          downloadFullPlan
        }
        footerCopyright
        footerNav {
          link
          name
        }
        footerNewsLetter {
          placeHolder
          thankYou
          title
        }
        lightbox {
          cta {
            carousel
            enlarge
          }
        }
        mainNav {
          link
          name
        }
        newsEvents {
          cta {
            events
            news
            register
            viewAll
          }
          filter {
            all
            events
            news
            showing
          }
          title
          indexTitle
        }
        siteMetaData {
          author
          description
          title
        }
        translations {
          cta
          languages {
            link
            name
          }
        }
      }
    }
  }
  allBnJson {
    edges {
      node {
        cta {
          back
          learnMore
          downloadPlan
          downloadFullPlan
        }
        footerCopyright
        footerNav {
          link
          name
        }
        footerNewsLetter {
          placeHolder
          thankYou
          title
        }
        lightbox {
          cta {
            carousel
            enlarge
          }
        }
        mainNav {
          link
          name
        }
        newsEvents {
          cta {
            events
            news
            register
            viewAll
          }
          filter {
            all
            events
            news
            showing
          }
          title
          indexTitle
        }
        siteMetaData {
          author
          description
          title
        }
        translations {
          cta
          languages {
            link
            name
          }
        }
      }
    }
  }
}
`

export default Homepage
