import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from  '@xstyled/styled-components'
import { Link } from 'gatsby'

import LinkIcon from './link-icon'

const LinkStyle = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 390rpx;
  color: var(--ssy-black);
  text-decoration: none;
  border: 1px;
  border-style: solid;
  border-color: rgba(19, 19, 19, 0.1);
  background-color: var(--ssy-white);

  h6 {
    line-height: 21rpx;
  }

  .icon-arrow-up-right {
    position: relative;
    top: 2rpx;
  }

  &:hover {
    color: var(--ssy-white);
    background-color: var(--ssy-orange);

    .link-icon {
      color: var(--ssy-white);
    }
    .icon-arrow-up-right g > g {
      fill: var(--ssy-white);
    }
  }
`

const StyledLink = styled(Link)`
  ${LinkStyle}
`

const StyledExternalLink = styled.a`
  ${LinkStyle}
`

const StyledStaticCard = styled.div`
  ${LinkStyle}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  height: 100%;

  .link-icon {
    margin-top: auto;
    bottom: 30rpx;
  }
`

const Label = styled.div`
  margin-bottom: 8rpx;
  font-size: 13rpx;
  line-height: 18rpx;
  font-weight: 500;
  text-transform: uppercase;
`

const EventHeader = styled.div`
  position: relative;
  display: ${p => p.image ? `initial` : `flex`};
  justify-content: ${p => p.image ? `initial` : `flex-end`};
  padding: ${p => p.image ? `0` : `20px 20px 14px 20px`};

  img {
    width: 100%;
  }
`

const DateBubble = styled.div`
  position: ${p => p.image ? `absolute` : `initial`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60rpx;
  height: 60rpx;
  font-size: 13rpx;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 50%;
  color: var(--ssy-white);
  background-color: var(--ssy-orange);
  bottom: 20rpx;
  right: 20rpx;

`
const Month = styled.div`
  font-size: 13rpx;
  line-height: 18rpx;
  font-weight: 500;
`

const Day = styled.div`
  position: relative;
  font-size: 24rpx;
  line-height: 18rpx;
  font-weight: 800;
  top: 2rpx;
`

const NewsEventsCard = ({cta, date, image, linkExt, slug, title, type }) => {

  if (process.env.GATSBY_CONTACT_URL && image) {
    image = image.replace(process.env.GATSBY_DRUPAL_BASE_URL, process.env.GATSBY_CONTACT_URL);
  }

  const formatDate = (string, format) => {
    const newDateUTC = new Date(string)
    const newDate = new Date(newDateUTC.getTime()+newDateUTC.getTimezoneOffset()*60*1000)
    let options

    if (format ===  'full') {
      options = { month: 'short', day: 'numeric', year: 'numeric' }
    }
    if (format ===  'month') {
      options = { month: 'short' }
    }
    if (format ===  'day') {
      options = { day: 'numeric' }
    }

    const formattedDate = newDate.toLocaleDateString('en-US', options);
    return (
      formattedDate
    )
  }

  if (linkExt) {
    return (
      <StyledExternalLink href={linkExt} className="news-card" rel="noopener" target="_blank">
        {type === 'event' && (
          <EventHeader image={image}>
            {image && (
              <img src={image} alt=""/>
            )}
            <DateBubble image={image}>
              <Month>{formatDate(date, 'month')}</Month>
              <Day>{formatDate(date, 'day')}</Day>
            </DateBubble>
          </EventHeader>
        )}
        <Content>
          <Label>{`${type}: ${formatDate(date, 'full')}`}</Label>
          <h6>{title}</h6>
          <LinkIcon
            text={cta}
            iconUpArrow
            staticLink
          />
        </Content>
      </StyledExternalLink>
    )
  }

  if (slug) {
    return (
      <StyledLink to={`/news/${slug}`} className="news-card">
        {type === 'event' && (
          <EventHeader image={image}>
            {image && (
              <img src={image} alt=""/>
            )}
            <DateBubble image={image}>
              <Month>{formatDate(date, 'month')}</Month>
              <Day>{formatDate(date, 'day')}</Day>
            </DateBubble>
          </EventHeader>
        )}
        <Content>
          <Label>{`${type}: ${formatDate(date, 'full')}`}</Label>
          <h6>{title}</h6>
          {cta && (
            <LinkIcon
              text={cta}
              iconUpArrow
              staticLink
            />
          )}
        </Content>
      </StyledLink>
    )
  }

  return (
    <StyledStaticCard className="news-card">
        {type === 'event' && (
          <EventHeader image={image}>
            {image && (
              <img src={image} alt=""/>
            )}
            <DateBubble image={image}>
              <Month>{formatDate(date, 'month')}</Month>
              <Day>{formatDate(date, 'day')}</Day>
            </DateBubble>
          </EventHeader>
        )}
        <Content>
          <Label>{`${type}: ${formatDate(date, 'full')}`}</Label>
          <h6>{title}</h6>
        </Content>
      </StyledStaticCard>
  )
}

export default NewsEventsCard

NewsEventsCard.propTypes = {
  cta:  PropTypes.string,
  date: PropTypes.string,
  image: PropTypes.string,
  linkExt: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string
}
