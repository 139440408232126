import React, { useEffect, useState } from 'react'
import styled, { Box, css, down } from '@xstyled/styled-components'
import { Link } from "react-scroll";
import anime from 'animejs'

const FixedNav = styled(Box)`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-items: flex-end;
  top: calc(50vh - 70px);
  right: 0;
  z-index: 100;
  width: ${p => p.navHovered ? `400px` : 0};
  transition: width ${p => p.introplayed ?
  `0.25s ease-in-out, padding 0.25s ease-in-out, background-color 0.25s ease-in-out, color 0.25s ease-in-out`
  :
  `0.5s ease-in-out, padding 0.5s ease-in-out, background-color 0.5s ease-in-out, color 0.5s ease-in-out`
};

  ${down(
  'md',
  css`
      display: none;
    `
)}
`

const NavItem = styled(Link)`
  display: block;
  width: 0%;
  margin-bottom: 10rpx;
  padding: 6rpx 15rpx;
  background-color: var(--ssy-orange);
  color: transparent;
  font-size: 15rpx;
  font-weight: 800;
  text-decoration: none;
  transition: width ${p => p.introplayed ?
  `0.25s ease-in-out, padding 0.25s ease-in-out, background-color 0.25s ease-in-out, color 0.25s ease-in-out`
  :
  `0.5s ease-in-out, padding 0.5s ease-in-out, background-color 0.5s ease-in-out, color 0.5s ease-in-out`
};
  white-space: nowrap;
  text-align: center;
  max-width: fit-content;
  -webkit-appearance: none;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    width: 100%;
    padding: 6rpx 60rpx;
    background-color: var(--ssy-black);
    color: var(--ssy-white);
    cursor: pointer;
  }

  &.active {
    width: 0%;
    padding: 6rpx 15rpx;
    background-color: var(--ssy-black);
    color: transparent;

    &:hover {
      width: 100%;
      padding: 6rpx 60rpx;
      background-color: var(--ssy-black);
      color: var(--ssy-white);
      cursor: pointer;
    }
  }
`

const Navigation = ({ data }) => {
  const { mainNav } = data
  let NavIntroPlayed = false;
  if (typeof window !== 'undefined') {
    NavIntroPlayed = sessionStorage.getItem('NavIntroPlayed')
  }

  const [navHovered, setNavHovered] = useState(false);


  useEffect(() => {

    if (!NavIntroPlayed) {
      const initialNavAnime = anime.timeline({
        targets: '.nav-item',
        delay: anime.stagger(150),
        easing: 'easeInOutCubic',
        duration: 300,
        autoplay: false
      })

      initialNavAnime
        .add({
          color: ['rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 1)'],
          backgroundColor: ['#D9381C', '#000'],
          width: ['0%', '100%'],
          padding: ['6px 15px', '6px 60px']
        })
        .add({
          color: ['rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 0)'],
          backgroundColor: ['#000', '#D9381C'],
          width: ['100%', '0%'],
          padding: ['6px 60px', '6px 15px'],
          complete: function () {
            const element = document.querySelectorAll('#nav-home .nav-item')
            element.forEach(el => {
              el.removeAttribute('style')
              sessionStorage.setItem('NavIntroPlayed', true)
            })
          }
        })
      setTimeout(()=> {
        initialNavAnime.play()
      }, 1000)

    }
  })

  return (
    <FixedNav id="nav-home" navHovered={navHovered}>
      {mainNav.map(nav => (
        <NavItem
          key={nav.name}
          to={nav.link}
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
          activeClass="active"
          className="nav-item"
          introplayed={NavIntroPlayed}
          onMouseEnter={() => setNavHovered(true)}
          onMouseLeave={() => setNavHovered(false)}>
          {nav.name}
        </NavItem>
      ))}
    </FixedNav>
  )
}

export default Navigation
