import React from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'
import styled, { Box } from '@xstyled/styled-components'
import parse from 'html-react-parser'
import anime from 'animejs'

import Container from '../layout/container'
import ImageModal from '../home/image-modal'
import SectionInnerWrapper from './section-inner-wrapper'
import { GatsbyImage } from "gatsby-plugin-image";

const StyledSection = styled.section``

const ColumnText = styled(Box)`
  opacity: 0;
  transform: translateY(50px);
`
const ColumnImage = styled(Box)`
  opacity: 0;
  transform: translateY(-200px);

  img {
    width: 100%;
    height: auto;
  }
`

const ImageTopBox = styled(Box)`
  justify-content: center;
`

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: auto;
  border-bottom: 20px solid var(--ssy-orange);
`

const SectionImageText = ({
  alt, className, id, image, imageId,
  alignment, text, imageModal, data }) => {
  let animeFinished = false

  const animateSection = (isVisible) => {
    if (isVisible && !animeFinished) {
      const animateTxt = anime.timeline({
        easing: 'easeOutQuad',
        duration: 1200,
        autoplay: false
      })

      animateTxt
      .add({
        targets: `#${id} .column-text`,
        opacity: [0, 1],
        translateY: [50, 0],
      })
      .add({
        targets: `#${id} .column-image`,
        opacity: [0, 1],
        translateY: ['-20px', 0],
        easing: 'easeOutQuad',
        complete: function () {
          animeFinished = true
        }
      }, '-=1000')

      animateTxt.play()
    }
  }

  const imageAlignment = (alignment) => {
    if (alignment === 'imageRight') {
      return (
        <Box row mx={{ xs: -10, md: -27.5 }} className="row-image-right">
          <ColumnText col={{ xs: 1, sm: 1, md: 1, lg: 1 / 2 }} p={{ xs: 10, md: 27.5 }} className="column-text">
            {parse(text)}
          </ColumnText>
          <ColumnImage col={{ xs: 1, sm: 1, md: 1, lg: 1 / 2 }} p={{ xs: 10, md: 27.5 }} className="column-image">
            {imageModal ? (
              <ImageModal data={data} id={imageId}>
                <GatsbyImage image={image} alt={alt} />
              </ImageModal>
            ) : (
              <StyledImage fluid={image} alt={alt} />
            )}
          </ColumnImage>
        </Box>
      );
    }
    else if (alignment === 'imageTop') {
      return (
        <ImageTopBox row mx={{ xs: -10, md: -27.5 }} className="row-image-center">
          <ColumnText col={{ xs: 1, sm: 1, md: 1, lg: 1 / 2 }} p={{ xs: 10, md: 27.5 }} className="column-text">
            {imageModal ? (
              <ImageModal data={data} id={imageId}>
                <GatsbyImage image={image} alt={alt} />
              </ImageModal>
            ) : (
              <StyledImage fluid={image} alt={alt} />
            )}
            {parse(text)}
          </ColumnText>
        </ImageTopBox>
      );
    }
    return (
      <Box row mx={{ xs: -10, md: -27.5 }} className="row-image-left">
        <ColumnImage col={{ xs: 1, sm: 1, md: 1, lg: 1 / 2 }} p={{ xs: 10, md: 27.5 }} className="column-image">
          {imageModal ? (
            <ImageModal data={data} id={imageId}>
              <GatsbyImage image={image} alt={alt} />
            </ImageModal>
          ) : (
            <StyledImage fluid={image} alt={alt} />
          )}
        </ColumnImage>
        <ColumnText col={{ xs: 1, sm: 1, md: 1, lg: 1 / 2 }} p={{ xs: 10, md: 27.5 }} className="column-text">
          {parse(text)}
        </ColumnText>
      </Box>
    );
  }

  return (
    <StyledSection id={id} className={className}>
      <VisibilitySensor onChange={isVisible => animateSection(isVisible)} partialVisibility>
      <SectionInnerWrapper>
        <Container>
         {imageAlignment(alignment)}
        </Container>
      </SectionInnerWrapper>
      </VisibilitySensor>
    </StyledSection>
  )
}

export default SectionImageText

SectionImageText.defaultProps = {
  alignment: 'imageLeft',
  alt: '',
  className: 'section',
  image: 'https://via.placeholder.com/640x480',
  imageId: '',
  text: '',
}

SectionImageText.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  image: PropTypes.shape({
    aspectRatio: PropTypes.number,
    base64: PropTypes.string,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }),
  imageId: PropTypes.string,
  alignment: PropTypes.string,
  text: PropTypes.string,
}
