import React from 'react'
import PropTypes from 'prop-types'
import styled from '@xstyled/styled-components'
import parse from 'html-react-parser'


const StyledBubble = styled.div`
  position: relative;
  background-color: var(--ssy-gray);
  border-radius: 50%;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

const BubbleContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15rpx;
`
const BubbleIcon = styled.div`
  margin-bottom: 0;

  svg {
    width: 50rpx;
    height: auto;
    margin-bottom: 10rpx;
    /* large icons */
    &.icon-park {
      width: 72rpx;
      height: 96rpx;
    }
    &.icon-tools {
      width: 96rpx;
      height: 96rpx;
    }
    &.icon-station {
      width: 96rpx;
      height: 76.8rpx;
    }
    /* small icons */
    &.icon-library {
      width: 43.75rpx;
      height: 50rpx;
    }
    &.icon-subway {
      width: 43.02rpx;
      height: 50rpx;
    }
  }
  
`

const BubbleTitle = styled.div`
  font-size: 24rpx;
  line-height: 36rpx;
  font-weight: 800;
`
const BubbleText = styled.div`
  font-size: 18rpx;
  line-height: 24rpx;
`
const BubbleBar = styled.div`
  position: relative;
  top: -17%;
  width: 100%;
  height: 20rpx;
  background-color: var(--ssy-orange);
`

const TextBubble = ({ icon, title, subtitle }) => {
  return (
    <>
      <StyledBubble className="text-bubble">
        <BubbleContent>
          {icon ? (<BubbleIcon>{icon}</BubbleIcon>) : ''}
          {title ? (<BubbleTitle>{title}</BubbleTitle>) : ''}
          {subtitle ? (<BubbleText>{parse(subtitle)}</BubbleText>) : ''}
        </BubbleContent>
      </StyledBubble>
      <BubbleBar />
    </>
  )
}

export default TextBubble

TextBubble.defaultProps = {
  subtitle: '',
  title: '',
}

TextBubble.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.node
  ]),
}