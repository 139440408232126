import React from 'react'
import PropTypes from 'prop-types'
import styled, { Box, css, up } from '@xstyled/styled-components'
import VisibilitySensor from 'react-visibility-sensor'
import parse from 'html-react-parser'
import anime from 'animejs'

import Container from '../layout/container'
import ImageGridItem from './image-grid-item'
import SectionInnerWrapper from './section-inner-wrapper'

const StyledSection = styled.section``

const SectionTitle = styled(Box)`
  margin-bottom: 24rpx;
  text-align: center;

  h4 {
    margin-bottom: 16rpx;
  }

  ${up(
    'md',
    css`
      margin-bottom: 130rpx;
    `
  )}
`

const ImageGrid =  styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-column-gap: 55rpx;
  grid-row-gap: 55rpx;

  ${up(
    'md',
    css`
      grid-template-columns: 1fr 1fr;
    `
  )}

${up(
    'lg',
    css`
      grid-template-columns: 1fr 1fr;
    `
  )}

${up(
    'xl',
    css`
      grid-template-columns: 1fr 1fr 1fr 1fr;
    `
  )}
`

const SectionImageGrid = ({ className, items, id, title }) => {
  let animeFinished = false

  const animateSection = (isVisible) => {
    if (isVisible && !animeFinished) {
      const animeSection = anime.timeline({
        easing: 'easeInOutQuad',
        duration: 500,
        autoplay: false
      })

      animeSection
        .add({
          targets: `#${id} .image-grid-item`,
          delay: anime.stagger(200),
          opacity: [0, 1],
          complete: function () {
            animeFinished = true
          }
        })

      animeSection.play()
    }
  }

  return (
    <StyledSection id={id} className={className}>
      <VisibilitySensor onChange={isVisible => animateSection(isVisible)}>
        <SectionInnerWrapper>
          <Container>
            <Box row justifyContent="center">
              <SectionTitle col={{ xs: 1, md: 2 / 3 }}>
                {parse(`${title}`)}
              </SectionTitle>
            </Box>
            <ImageGrid>
              {items.map(item => (
                <ImageGridItem
                  className="image-grid-item"
                  image={item.urlSharp.childImageSharp.gatsbyImageData}
                  title={item.title}
                  alt={item.alt}
                  key={item.entity.uuid}
                />
              ))}
            </ImageGrid>
          </Container>
        </SectionInnerWrapper>
      </VisibilitySensor>
    </StyledSection>
  );
}

export default SectionImageGrid

SectionImageGrid.defaultProps = {
  className: 'section',
  id: ''
}

SectionImageGrid.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
}
