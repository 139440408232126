import React from 'react'
import PropTypes from 'prop-types'
import styled, { Box, css, up } from '@xstyled/styled-components'
import VisibilitySelector from 'react-visibility-sensor'
import anime from 'animejs'
import _ from 'underscore'

import Button from '../button'
import Container from '../layout/container'
import NewsEventsCard from '../news-events-card'
import SectionInnerWrapper from './section-inner-wrapper'

const StyledSection = styled.section`
  .news-card {
    opacity: 0;
  }
`
const SectionTitle = styled.h1`
  text-align: center;
`
const LineWrapper = styled.div`
  width: 1rpx;
  height: 100rpx;
  margin: 60rpx auto;
`
const Line = styled.div`
  width: 1rpx;
  height: 0;
  background-color: var(--ssy-black);
`
const StyledButton = styled(Button)`
  margin: 60rpx auto 0;
  opacity: 0;

  ${up(
    'sm',
    css`
      width: 405px;
    `
  )}
`

const SectionNewsEvents = ({ className, id, data, newsEventsData, lang }) => {
  let animeFinished = false

  const animateSection = (isVisible) => {
    if (isVisible && !animeFinished) {
      const animateBtns = anime.timeline({
        duration: 600,
        autoplay: false,
        easing: 'easeOutQuad'
      })

    animateBtns
      .add({
        targets: `#${id} .line`,
        height: ['0px', '100px'],
      })
      .add({
        targets: `#${id} .news-card`,
        opacity: [0, 1],
        delay: anime.stagger(300),
      })
      .add({
        targets: `#${id} .btn-view-all`,
        opacity: [0, 1],
        complete: function () {
          animeFinished = true
        }
      }, '-=800')

    animateBtns.play()
    }
  }

  return (
    <StyledSection id={id} className={className}>
      <VisibilitySelector onChange={isVisible => animateSection(isVisible)} partialVisibility>
        <SectionInnerWrapper newsEvents>
          <Container>
            <SectionTitle className="h1-alt">{data.title}</SectionTitle>
            <LineWrapper>
              <Line className="line" />
            </LineWrapper>
            <Box row m={-27.5}>
              {newsEventsData.entities.map((element, index) => (
                <Box col={{ xs: 1, sm: 1 / 2, md: 1 / 2, lg: 1 / 4 }} p={27.5} alignItems="stretch" key={id}>
                  <NewsEventsCard
                    cta={element.fieldExternalLink && element.fieldExternalLink.title}
                    date={element.fieldDate.value}
                    image={element.fieldCoverImage && element.fieldCoverImage.url}
                    linkExt={element.fieldExternalLink && element.fieldExternalLink.url.path}
                    slug={element.fieldSlug}
                    title={element.entityLabel}
                    type={element.fieldType}
                  />
                </Box>
              ))}
            </Box>
            <StyledButton
              aria-label={data.cta.viewAll}
              url={`${lang === 'EN' ? '' : `/${lang}`}/news`}
              className="btn-view-all"
              text={data.cta.viewAll}
              fullWidth
              />
          </Container>
        </SectionInnerWrapper>
      </VisibilitySelector>
    </StyledSection>
  )
}

export default SectionNewsEvents

SectionNewsEvents.defaultProps = {
  className: 'section',
  id: '',
  lang: 'EN'
}


SectionNewsEvents.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  lang: PropTypes.string,
}
