import React from 'react'
import PropTypes from 'prop-types'
import styled, { Box, css, up, down } from '@xstyled/styled-components'
import VisibilitySensor from 'react-visibility-sensor'
import parse from 'html-react-parser'
import anime from 'animejs'

import Container from '../layout/container'
import TextBubble from './text-bubble'
import SectionInnerWrapper from './section-inner-wrapper'

import IconHospital from '../../svgs/icons/icon-hospital.svg'
import IconLibrary from '../../svgs/icons/icon-library.svg'
import IconSchool from '../../svgs/icons/icon-school.svg'
import IconHome from '../../svgs/icons/icon-home.svg'
import IconSubway from '../../svgs/icons/icon-subway.svg'
import IconJobs from '../../svgs/icons/icon-jobs.svg'
import IconBus from '../../svgs/icons/icon-bus.svg'



const StyledSection = styled.section`
  text-align: center;
  margin-top: -40rpx;

  .section-column {
    scale: 0.1;
    opacity: 0;
  }
`

const SectionTitle = styled(Box)`
  margin-bottom: 40rpx;

  h4{
    margin-bottom: 36rpx;
  }

  ${down(
    'md',
    css`
      h2 {
        font-size: 24rpx;
        line-height: 30rpx;
      }
    `
  )}
`

const StyledSectionInnerWrapper = styled(SectionInnerWrapper)`
padding: 0 0 60rpx;

${up(
  'md',
  css`
    padding: 0 0 150px;
  `
)}
`

const SectionSuperTitle = styled(Box)`
  font-size: 13rpx;
  line-height: 18rpx;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 16rpx;

  ${up(
    'md',
    css`
      font-size: 18rpx;
      line-height: 18rpx;
      margin-bottom: 40rpx;
    `
  )}
`

const SectionRow = styled(Box)``

const SectionColumn = styled(Box)`
  transform: translateY(80vh);
`

const SectionTextBubblesTwoRows = ({className, id, items, title, superTitle }) => {
  let animeFinished = false

  const animateSection = (isVisible) => {
    if (isVisible && !animeFinished) {
      const animateColumns = anime.timeline({
        easing: 'easeOutElastic(1, .7)',
        duration: 1000,
        autoplay: false,
      })

      animateColumns
        .add({
          targets: `#${id} .section-column`,
          // translateY: ['80vh', 0],
          scale: [0.1, 1],
          opacity: [0, 1],
          delay: anime.stagger(300),
          complete: function () {
            animeFinished = true
          }
        })

      animateColumns.play()
    }
  }
  const iconsListPlan = [
  <IconHome/>,<IconJobs/>,<IconSchool />,
  <IconLibrary />, <IconHospital />,
  <IconSubway />, <IconBus /> ]
  const firstRow = items.slice(0, 4);
  const secondRow = items.slice(4,);

  return (
    <StyledSection id={id} className={className}>
      <VisibilitySensor onChange={animateSection} partialVisibility>
        <StyledSectionInnerWrapper>
          <Container>
              <SectionSuperTitle>{superTitle}</SectionSuperTitle>
              <Box row>
                <SectionTitle col={{ xs: 1, sm: 1, lg: 2 / 3}} mx="auto" className="section-title">
                  {parse(title)}
                </SectionTitle>
              </Box>
              <SectionRow row m={-27.5} justifyContent="center">

                {firstRow.map( (item, index) => (
                  <SectionColumn
                    className="section-column"
                    col={{xs: 1, sm: 1, md: 1 / 2, xl: 1 / 4}}
                    p={27.5}
                    key={item.entity.entityTranslation.uuid}
                    >
                    <TextBubble
                      icon={iconsListPlan[index]}
                      title={item.entity.entityTranslation.fieldTitle}
                      subtitle={item.entity.entityTranslation.fieldSubtitle}
                    />
                  </SectionColumn>
                ))}
              </SectionRow>

              <SectionRow row m={-27.5} justifyContent="center">
                {secondRow.map( (item, index) => (
                  <SectionColumn
                    key={item.entity.entityTranslation.uuid}
                    className="section-column"
                    col={{xs: 1, sm: 1, md: 1 / 2, xl: 1 / 4}}
                    p={27.5}
                    >
                    <TextBubble
                      icon={iconsListPlan[index+4]}
                      title={item.entity.entityTranslation.fieldTitle}
                      subtitle={item.entity.entityTranslation.fieldSubtitle}
                    />
                  </SectionColumn>
                ))}
              </SectionRow>
            </Container>
        </StyledSectionInnerWrapper>
      </VisibilitySensor>
    </StyledSection>
  )
}

export default SectionTextBubblesTwoRows

SectionTextBubblesTwoRows.defaultProps = {
  className: 'section',
  id: '',
  title: ''
}

SectionTextBubblesTwoRows.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  // items: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
}
