import React from 'react'
import PropTypes from 'prop-types'
import styled,  { css, up } from '@xstyled/styled-components'

import IconZoom from '../../svgs/icons/icon-zoom.svg'
import IconSlideshow from '../../svgs/icons/icon-slideshow.svg'
import { GatsbyImage } from "gatsby-plugin-image";

const ImageWrapper = styled.button`
  width: ${p => p.fullWidth ? '100vw' : '100%'};
  margin: 0;
  padding: 0;
  border: 0;
  background-color: var(--ssy-gray);
  margin-bottom: 34rpx;
  overflow-x: ${p => p.fullWidth ? 'hidden' : 'initial'};

  img {
    display: block;
    width: ${p => p.fullWidth ? 'auto' : '100%'};
    min-width: 100%;
    height: ${p => p.fullWidth ?  '40vh' : 'auto'};

    ${up(
      'md',
      css`
        width: 100%;
        height: auto;
      `
    )}

  }

  &:hover {
    cursor: pointer;
  }
`

const IconWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  padding: 16rpx 20rpx;
  font-size: 14rpx;
  line-height: 18rpx;
  font-weight: 800;
  color: var(--ssy-white);
  background-color: var(--ssy-orange);

  svg {
    width: 16rpx;
    height: 16rpx;
    margin-left: 10rpx;

    & > g > g {
      fill: var(--ssy-white)
    }
  }
`

const ImageModal = ({ image, children, id, fullWidth, data }) => {
  return (
    <ImageWrapper
      type="button"
      aria-label="display image modal"
      className="image-modal"
      data-modal-image-id={id}
      fullWidth={fullWidth}
      >
      {children ? (
        <>
          {children}
          <IconWrapper>
            {data.lightbox.cta.enlarge} <IconZoom />
          </IconWrapper>
        </>
      ) :
      (
        <>
          <GatsbyImage
            image={image[0].urlSharp.childImageSharp.gatsbyImageData}
            alt={image[0].alt} />
          <IconWrapper>
            {image && image.length === 1 ?
              data.lightbox.cta.enlarge
                :
              data.lightbox.cta.carousel}
              {image.length > 1 ?
                (<IconSlideshow />)
                  :
                (<IconZoom />)}
          </IconWrapper>
        </>
      )}

    </ImageWrapper>
  );
}

export default ImageModal

ImageModal.defaultProps = {
  children: null,
  fullWidth: false,
  id: '',
  image: null
}

ImageModal.propTypes = {
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]).isRequired,
  id: PropTypes.string.isRequired,
  image: PropTypes.arrayOf(PropTypes.object),
}
