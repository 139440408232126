import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, up } from '@xstyled/styled-components'
import { GatsbyImage } from "gatsby-plugin-image";


const ImageGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40rpx;
  grid-row-gap: 40rpx;
  margin: 40rpx 0;

  img {
    width: 100%;
    height: auto;
  }

  ${up(
    'lg',
    css`
      grid-template-columns: 1fr 1fr;
    `
  )}
`
const ImageGridPopOut = ({ className, images }) => (
  <ImageGrid className={className}>
    {images.map(image => (
      <GatsbyImage
        image={image.urlSharp.childImageSharp.gatsbyImageData}
        key={image.entity.uuid}
        alt={image.alt} />
    ))}
  </ImageGrid>
)

export default ImageGridPopOut

ImageGridPopOut.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string,
    entity: PropTypes.shape({
      uuid: PropTypes.string,
    }),
  }))
}
