import React from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'
import styled, { Box } from '@xstyled/styled-components'
import parse from 'html-react-parser'
import anime from 'animejs'

import Container from '../layout/container'
import ImageModal from './image-modal'
import { GatsbyImage } from "gatsby-plugin-image";
import SectionInnerWrapper from  './section-inner-wrapper'


const StyledSection = styled.section``

const SectionTitle = styled(Box)`
  margin-bottom: 24rpx;
  text-align: center;
  opacity: 0;
`

const SectionImageTitle = ({ className, data, id, image, title }) => {
  let animeFinished = false

  const sectionAnimation = (isVisible) => {
    if (isVisible && !animeFinished) {
      anime({
        targets: `#${id} .section-title`,
        opacity: [0, 1],
        easing: 'easeInOutQuad',
        complete: function () {
          animeFinished = true
        }
      })
    }
  }

  return (
    <StyledSection id={id} className={className}>
      <VisibilitySensor onChange={isVisible => sectionAnimation(isVisible)} partialVisibility>
        <SectionInnerWrapper>
          {title ? (
            <Container>
              <Box row>
                <SectionTitle col={{xs: 1, sm: 1, md: 2 / 3}} mx="auto" className="section-title">
                  {parse(`${title}`)}
                </SectionTitle>
              </Box>
            </Container>
            ) :
            ''
          }
          <ImageModal id={image.entity.uuid} data={data} fullWidth>
            <GatsbyImage
              image={image.urlSharp.childImageSharp.gatsbyImageData}
              alt={image.entity.alt} />
          </ImageModal>
        </SectionInnerWrapper>
      </VisibilitySensor>
    </StyledSection>
  );
}

export default SectionImageTitle

SectionImageTitle.defaultProps = {
  alt: '',
  className: 'section',
  id: '',
  image: '',
  title: false
}

SectionImageTitle.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool
  ])
}
