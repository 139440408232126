import React from 'react'
import PropTypes from 'prop-types'
import styled, { Box } from '@xstyled/styled-components'
import VisibilitySensor from 'react-visibility-sensor'
import parse from 'html-react-parser'
import anime from 'animejs'

import Container from '../layout/container'
import SectionInnerWrapper from  './section-inner-wrapper'

const StyledSection = styled.section``

const SectionTitle =  styled(Box)`
  text-align: center;
  opacity: 0;

  h4 {
    margin-bottom: 16rpx;
  }

  h6 {
    margin-bottom: 16rpx;
    text-transform: uppercase;
  }

  p {
    margin-bottom: ${p => p.info ? '5px' : 'initial'}
  }
`

const Circle = styled.div`
  position: relative;
  display: flex;
  width: 55rpx;
  height: 55rpx;
  margin: 0 auto;
  bottom: -23rpx;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--ssy-orange);
  color: var(--ssy-white);
  font-size: 24rpx;
  line-height: 27rpx;
  font-weight: 800;
`

const Card = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(19, 19, 19, 0.2);
  font-size: 18rpx;
  line-height: 24rpx;
  font-weight: 800;
  text-align: center;
  padding: 50rpx 20rpx;
  align-content: center;
`

const CardColumn = styled(Box)`
  display: flex;
  flex-direction: column;
`

const SectionTitleText = ({ className, id, items, title }) => {
  let animeFinished = false

  const animateSection = (isVisible) => {

    if (isVisible && !animeFinished) {
      const animateBkg = anime.timeline({
        duration: 1000,
        easing: 'easeInOutQuad',
        autoplay: false,
      })

      animateBkg
        .add({
          targets: `#${id}  .section-title`,
          opacity: [0, 1],
          complete: function () {
            animeFinished = true
          }
        })

      animateBkg.play()
    }
  }

  return (
    <StyledSection id={id} className={className}>
      <VisibilitySensor onChange={isVisible => animateSection(isVisible)} partialVisibility>
        <SectionInnerWrapper>
          <Container>
            <Box row justifyContent="center">
              <SectionTitle  col={{xs: 1, sm: 1, md: 2 / 3}} className="section-title" items={items}>{parse(title)}</SectionTitle>
            </Box>

            {items ? (
              <Box row m={{xs:-10, sm:-27.5}}>
                {items.map(({ entity }, index) => {
                  const number = index + 1
                  return (
                    <CardColumn col={{ xs: 1, md: 1 / 3, xl: 1 / 6 }} p={{xs: 10, sm:27.5}} key={entity.entityTranslation.uuid} display="flex" alignItems="stretch">
                      <Circle>{number}</Circle>
                      <Card>
                        {entity.entityTranslation.fieldSectionTitle}
                      </Card>
                    </CardColumn>
                  )
                })}
              </Box>
            ) :
            ''}
          </Container>
        </SectionInnerWrapper>
      </VisibilitySensor>
    </StyledSection>
  )
}

export default SectionTitleText

SectionTitleText.defaultTypes = {
  className: 'section',
  title: '',
}

SectionTitleText.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
}
