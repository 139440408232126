import React from 'react'
import styled, { css, up } from '@xstyled/styled-components'

import PriorityTitle from './priority-title'

const MobileWrapper = styled.div`
  position: sticky;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 140rpx;
  background-color: var(--ssy-orange);
  top: 0;
  left: 0;
  right: 0;
  z-index: 96;

  &.display {
    display: flex;
  }

  ${up(
    'lg',
    css`
      display: none;
    `
  )}
`

const DesktopWrapper = styled.div`
  position: sticky;
  display: none;
  width: 42.3vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 96;
  align-items: center;
  justify-content: flex-end;

  ${up(
    'lg',
    css`
      display: flex;
      align-items: center;
    `
  )}
`

const DesktopInnerWrapper = styled.div`
  width: 42.3vw;
  margin-right: auto;
  position: relative;
  display: flex;
  justify-content: center;
`

const PriorityTitleSticky = ({ data, lang }) => {
  return (
    <>
      <MobileWrapper className="priority-title-sticky-mobile">
        {data.map((section, indexLevelOne) => (
          <PriorityTitle
            key={section.entity.entityTranslation.uuid}
            index={indexLevelOne}
            title={section.entity.entityTranslation.fieldSectionTitle}
            summary={section.entity.entityTranslation.fieldSectionSummary.processed}
            lang={lang}
            />
          )
        )}
      </MobileWrapper>

        <DesktopWrapper className="priority-title-sticky-desktop">
          <DesktopInnerWrapper className="desktop-inner-wrapper">
            {data.map((section, indexLevelOne) => (
              <PriorityTitle
                key={section.entity.entityTranslation.uuid}
                index={indexLevelOne}
                title={section.entity.entityTranslation.fieldSectionTitle}
                summary={section.entity.entityTranslation.fieldSectionSummary.processed}
                lang={lang}
                />
              )
            )}
          </DesktopInnerWrapper>
        </DesktopWrapper>
    </>
  )
}

export default PriorityTitleSticky
