import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, up } from '@xstyled/styled-components'
import VisibilitySensor from 'react-visibility-sensor'
import parse from 'html-react-parser'

import ImageModal from './image-modal'

const Priority = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 30rpx 0;
  overflow-y: auto;

  &:first-child {
    padding-top: 200rpx;
  }
  ${up(
    'lg',
    css`
      position: relative;
      top: -100vh;
      min-height: 100vh;
      padding: 60rpx 86rpx 0;
    `
  )}
`

const PriorityDetail = ({ data, items, onChange }) => {
  const { fieldText, fieldCoverImage} = items.entity.entityTranslation

  return (
    <Priority>
      <VisibilitySensor
        minTopPercent={0.51}
        onChange={isVisible => onChange(isVisible)}
        partialVisibility
        intervalCheck={true}
        intervalDelay={300}
        >
        <div>
          {fieldCoverImage[0] ? (
            <ImageModal
              image={fieldCoverImage}
              id={items.entity.entityTranslation.uuid}
              data={data}
              />
          ) : ''}
          {parse(`${fieldText.processed}`)}
        </div>
      </VisibilitySensor>
    </Priority>
  )
}

export default PriorityDetail

PriorityDetail.defaultTypes = {
  items: [{
    entity: {
      fieldText: {
        processed: '',
      }
    }
  }]
};

PriorityDetail.propTypes = {
  items: PropTypes.shape({
    entity: PropTypes.shape({
      fieldText: PropTypes.shape({
        processed: PropTypes.string.isRequired
      }),
      fieldCoverImage: PropTypes.arrayOf(PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string
      }))
    })
  })
};
