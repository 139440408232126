import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, up } from '@xstyled/styled-components'
import anime from 'animejs'

import IconClose from '../svgs/icons/icon-close-orange.svg'
import paragraphsResolver from './paragraphsResolver'

const StyledModal = styled.div`
  /* position: absolute; */
  position: fixed;
  overflow-y: initial !important;
  visibility: hidden;
  width: 75vw;
  background-color: var(--ssy-white);
  box-shadow: -10px 0 10px 0 rgba(0, 0, 0, 0.25);
  transform: translateX(110vw);
  top: 0;
  bottom: 0;
  z-index: -999;

  hr {
    margin: 50rpx 0 65rpx;
  }
`

const Content = styled.div`
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  height: 100%;
`

const BtnClose = styled.button`
  position: fixed;
  top: 24rpx;
  right: 12rpx;
  background-color: transparent;
  overflow-y: auto;
  border: 0;
  z-index: 101;

  svg {
    width: 13.5rpx;
    height: 13.5rpx;

    > g > g {
    transition: fill 0.5s ease-in-out;
    }
  }

  ${up(
    'md',
    css`
      top: 30rpx;
      right: 30rpx;

      svg {
        width: 20rpx;
        height: 20rpx;
      }
    `
  )}

  &:hover {
    cursor: pointer;

    svg > g > g {
      fill: var(--ssy-black);
    }
  }
`

const InnerWrapper = styled.div`
  padding: 40rpx 30rpx 30rpx;

  h1 {
    font-size: 30rpx;
    line-height: 36rpx;

    ${up(
      'md',
      css`
        font-size: 54rpx;
        line-height: 60rpx;
      `
    )}
  }

  ${up(
    'lg',
    css`
      padding: 100rpx 100rpx 30rpx;
    `
  )}
`

const Modal = ({ id, title, content }) => {
  const closeModal = () => {

    const closeModalAnime = anime.timeline({
      easing: 'easeInOutQuad',
      duration: 300,
      autoplay: false
    })

    closeModalAnime
      .add({
        targets: `#${id}`,
        translateX: ['25vw', '100vw'],
      })
      .add({
        targets: `.modal-overlay`,
        backgroundColor: ['rgba(0, 0, 0, 0.7)', 'rgba(0, 0, 0, 0)'],
        complete: function () {
          const scrollY = document.body.style.top
          const overlay = document.querySelector('.modal-overlay')
          const modal = document.getElementById(id)

          document.body.style.position = ''
          document.body.style.width =''
          window.scrollTo(0, parseInt(scrollY || '0') * -1)
          overlay.style.display = ''
          overlay.style.zIndex = ''
          modal.style.visibility = ''
          modal.style.zIndex = ''
        }
      })

    closeModalAnime.play()
  }

  return (
    <StyledModal id={id} className="modal">
      <BtnClose
        type="button"
        aria-label="close modal"
        onClick={closeModal}
        className="btn-close"
      >
        <IconClose />
      </BtnClose>
      <Content className="modal-content">
        <InnerWrapper>
          <h1>{title}</h1>
          <hr />
          {paragraphsResolver(content)}
        </InnerWrapper>
      </Content>
    </StyledModal>
  )
}

export default Modal


Modal.defaultProps = {
  id: '',
  content: '',
  title: '',
}

Modal.propTypes = {
  id: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}