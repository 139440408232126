import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, up } from '@xstyled/styled-components'
import VisibilitySensor from 'react-visibility-sensor'
import anime from 'animejs/lib/anime.es'
import parse from 'html-react-parser'
import { GatsbyImage } from "gatsby-plugin-image";

import Container from '../layout/container'
import LogoSSYMasterPlan from '../../svgs/logos/logo-ssy-master-plan.svg'
import LogoAmtrakWhite from '../../svgs/logos/logo-amtrak-white.svg'
import LogoNYCSealWhite from '../../svgs/logos/logo-nycseal-white.svg'

const ImageWrapper = styled.div `
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;

  .gatsby-image-wrapper {
    min-height: 100%;
  }
`

const StyledSection = styled.section`
  position: relative;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0 !important;

  .btn-translate {
    position: absolute;
    top: 30rpx;
    right: 0;
  }

  ${up(
  'lg',
  css`
      padding: 60rpx;
    `
)}
`

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  margin: 0 auto;
  padding: 40px;
  background-color: rgba(217, 56, 28, 0);
  color: var(--ssy-white);
  text-align: center;

  .logo-ssy-master-plan {
    width: 280rpx;
    height: auto;
    padding: 40rpx 0;

    ${up(
  'md',
  css`
      width: ${p => p.sharedVision ? '334px' : '560px'};
      padding: ${p => p.sharedVision ? '40px 0' : '60px 0'};
      margin-bottom: 0;
      `
)}
  }

  .anime-text {
    opacity: 0;
  }

  ${up(
  'xl',
  css`
      max-width: 1095rpx;
      min-height: 600rpx;
    `
)}
`

const Title = styled.div`
  display: block;
  font-size: 24rpx;
  line-height: 36rpx;
  font-weight: 800;

  &.large-text {
    font-size:  48rpx;
    line-height: 54rpx;
  }

  ${up(
  'md',
  css`
      font-size:  42rpx;
      line-height: 48rpx;
      margin: 0 55rpx;

      &.large-text {
        font-size:  72rpx;
        line-height: 80rpx;
      }
    `
  )}
`

const LineWrapper = styled.div`
  position: absolute;
  width: 1rpx;
  height: 240rpx;
  bottom: 0;
  left: calc(50vw - 0.5px);
  overflow: hidden;
`

const Line = styled.div`
  position: absolute;
  width: 1rpx;
  height: 240rpx;
  background-color: var(--ssy-white);
  top: 0;
  left: calc(50vw - 0.5rpx);
`

const InnerWrapper = styled.div`
  width: 100%;
  z-index: 10;
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;

  .logo-nyc-seal-white {
    width: calc(84.6px * 0.8);
    height: calc(90px * 0.8);
    margin-right: 15rpx;
  }

  .logo-amtrak-white {
    width: calc(120px * 0.8);
    height: calc(51px * 0.8);
  }

  ${up(
  'md',
  css`
    .logo-nyc-seal-white {
      width: 84.6rpx;
      height: 90rpx;
      margin-right: 15rpx;
    }

    .logo-amtrak-white {
      width: 120rpx;
      height: 51rpx
    }
  `
)}
`

const SectionIntro = ({
  className, title, id,
  lgText, btnTranslate, sharedVision, img }) => {
    let animeFinished = false
  const animateIntro = (isVisible) => {

    if (isVisible && !animeFinished) {
      const lineAnime = anime.timeline({
        easing: 'easeOutCubic',
        loop: true,
        autoplay: false
      })

      lineAnime
        .add({
          targets: `#${id} .line`,
          height: ['0px', '240px'],
          duration: 1100,
        })
        .add({
          targets: `#${id} .line`,
          translateY: ['0px', '240px'],
          duration: 800
        }, '-=600')

      const textAnime  = anime({
        targets: `#${id} .anime-text`,
        delay: anime.stagger(150),
        opacity: [0, 1],
        translateY: ['60px', '0px'],
        easing: 'easeOutCubic',
        duration: 1000,
        autoplay: false,
      })

      const sectionAnime = anime({
        targets: `#${id} .intro-title-block`,
        backgroundColor: [`rgba(217, 56, 28, 0)`, 'rgba(217, 56, 28, 0.95)'],
        easing: 'easeOutCubic',
        autoplay: false,
        duration: 1500,
      })

      sectionAnime.play()
      textAnime.play()
      lineAnime.play()
      animeFinished = true
    }
  }

  const getBackgroundImage = () => {
      if (!img) {
        return false;
      }
      switch (id) {
      case 'subsection-intro-the-plan':
        return <ImageWrapper><GatsbyImage image={img.childImageSharp.gatsbyImageData} alt='' /></ImageWrapper>;

      case 'subsection-intro-priorities':
        return <ImageWrapper><GatsbyImage image={img.childImageSharp.gatsbyImageData} alt='' /></ImageWrapper>;

      case 'subsection-plan-becomes-reality':
        return <ImageWrapper><GatsbyImage image={img.childImageSharp.gatsbyImageData} alt='' /></ImageWrapper>;

      case 'subsection-intro-a-shared-vision':
        return (
          <ImageWrapper><GatsbyImage
            image={img.childImageSharp.gatsbyImageData}
            loading='eager'
            fadeIn={false}
            alt='' /></ImageWrapper>
        );

      default:
        return false;
    }
  }



  return (
    <StyledSection id={id} className={className}>
      {getBackgroundImage()}
      <VisibilitySensor onChange={isVisible => animateIntro(isVisible)} partialVisibility>
        <InnerWrapper>
          {btnTranslate}
          <Container>
            <TitleBlock className="intro-title-block" sharedVision={sharedVision}>
              {sharedVision && (
                <LogoWrapper className="anime-text">
                  <LogoNYCSealWhite />
                  <LogoAmtrakWhite />
                </LogoWrapper>
              )}
              <LogoSSYMasterPlan className="logo-ssy-master-plan anime-text"/>
              <Title className={`anime-text ${lgText ? 'large-text' : ''}`}>{parse(title)}</Title>
            </TitleBlock>
          </Container>
          <LineWrapper>
            <Line className="line"/>
          </LineWrapper>
        </InnerWrapper>
      </VisibilitySensor>
    </StyledSection>
  )
}

export default SectionIntro

SectionIntro.defaultProps = {
  autoPlay: false,
  className: 'section',
  id: '',
  sectionName: '',
  lgText: false,
}

SectionIntro.propTypes = {
  autoPlay: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  lgText: PropTypes.bool,
  sectionName: PropTypes.string,
}
