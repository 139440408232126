import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, up } from '@xstyled/styled-components'
import parse from 'html-react-parser'
import LinkIcon from '../link-icon'

const Card = styled.div`
  padding: 30rpx 20rpx;
  border: 1px solid rgba(19, 19, 19, 0.1);

  ${up(
    'lg',
    css`
      padding: 40rpx 120rpx;
    `
  )}
`
// const CardTitle = styled.div`
//   font-size: 15rpx;
//   line-height: 24rpx;
//   font-weight: 800;
//   word-break: break-all;

//   ${up(
//     'md',
//     css`
//       font-size: 18rpx;
//       line-height: 30rpx;
//     `
//   )}

//   text-transform: capitalize;
// `
const CardBody = styled.div`
  margin-bottom: 24rpx;
  border-bottom: 1px solid rgba(19, 19, 19, 0.1);

  p {
    font-size: 15rpx;
    line-height: 24rpx;
    margin-bottom: 24rpx;
  }

  ${up(
    'md',
    css`
      margin-bottom: 30rpx;
      p {
        font-size: 18rpx;
        line-height: 30rpx;
      }
    `
  )}
`
const CardBottom = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  ${up(
    'sm',
    css`
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    `
  )}
`
const FileInfo = styled.div`
  font-size: 14rpx;
  line-height: 16rpx;
  text-transform: uppercase;
  margin-top: 24rpx;

  ${up(
    'sm',
    css`
      margin-top: 0;
    `
  )}
`

const FileLink = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10rpx;
`

const DocumentCard = ({ description, files, fileName }) => {

  // const trimFileName = (string) => {
  //   const fileName = string.split('.')
  //   return `${fileName[0]}`
  // }

  const trimFileExtension = (string) => {
    const fileName = string.split('.')
    return fileName.pop();
  }

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };
  return (
    <Card>
      <CardBody>{parse(description)}</CardBody>
      <CardBottom>
        {files.map(file=> (
          <FileLink key={file.entity.uuid}>
            <LinkIcon
              text={file.description}
              urlExt={file.entity.url}
              inline
              />
            <FileInfo>{trimFileExtension(file.entity.filename)} {formatBytes(file.entity.filesize)}</FileInfo>
          </FileLink>
          ))}
      </CardBottom>
    </Card>
  )
}

export default DocumentCard

DocumentCard.defaultProps = {
  fileName: '',
}

DocumentCard.propsTypes = {
  fileName: PropTypes.string,
}
