import React from 'react'
import PropTypes from 'prop-types'
import styled, { Box, css, up } from  '@xstyled/styled-components'
import VisibilitySensor from 'react-visibility-sensor'
import anime from 'animejs'
import parse from 'html-react-parser'

import Container from '../layout/container'
import { GatsbyImage } from "gatsby-plugin-image";
import InfoCard from '../home/info-card'

const StyledSection = styled.section`
  position: relative;
`

const InnerWrapper = styled.div``

const ColumnText = styled(Box)`
  opacity: 0;
  transform: translateY(50px);
`

const ColumnImage = styled(Box)`
  opacity: 0;
  transform: translateY(-200px);
`

const InfoCardWrapper = styled.div`
  width: 100vw;
  padding: 80rpx 0;
  background-color: var(--ssy-orange);
    
  ${up(
    'lg',
    css`
      height: 170rpx;
      padding: 0 0 18rpx;
    `
  )}
`

const TopContainer = styled(Container)`
  padding-top: 60rpx;

  ${up(
      'md',
      css`
        padding-top: 150rpx;
        padding-bottom: 100rpx;
      `
    )}
  
  ${up(
      'lg',
      css`
        padding-bottom: 260rpx;
      `
    )}
  `

const InfoCardInnerWrapper = styled.div`
  ${up(
    'lg',
    css`
      margin-top: 100px;
      position: relative;
      top: -12rpx;
    `
  )}
`

const BottomWrapper = styled.div`
  overflow: hidden;

  ${up(
    'lg',
    css`
      position: absolute;
      bottom: 0;
    `
  )}
`

const StyledImage = styled(GatsbyImage)`
  border-bottom: 20px solid var(--ssy-orange);
  margin-bottom: 40rpx;

  ${up(`lg`,
  css`
    margin-bottom: 0;
  `)}
`

const SectionInfoCards = ({ alt, className, id, image, items, text }) => {
  let animeFinished = false

  const animateSection = (isVisible) => {
    if (isVisible && !animeFinished) {
      const animateBtns = anime.timeline({
        easing: 'easeOutQuad',
        duration: 1200,
        autoplay: false,
      })

      animateBtns
        .add({
          targets: `#${id} .column-text`,
          opacity: [0, 1],
          translateY: [50, 0],
        })
        .add({
          targets: `#${id} .column-image`,
          opacity: [0, 1],
          translateY: ['-20px', 0],
          easing: 'easeOutQuad',
          complete: function () {
            animeFinished = true
          }
        }, '-=1000')
        .add({
          targets: `#${id} .info-card`,
          translateY: ['60vh', 0],
          easing: 'easeOutElastic(1, .7)',
          delay: anime.stagger(300),
          complete: function () {
            animeFinished = true
          }
        }, '-=500')

      animateBtns.play()
    }
  }

  return (
    <StyledSection id={id} className={className}>
      <VisibilitySensor onChange={isVisible => animateSection(isVisible)} partialVisibility>
        <InnerWrapper>
          <TopContainer>
            <Box row mx={{ xs: -10, md: -27.5 }} className="row-image-right">
              <ColumnText col={{ xs: 1, sm: 1, md: 1, lg: 1 / 2 }} p={{ xs: 10, md: 27.5 }} className="column-text">
                {parse(text)}
              </ColumnText>
              <ColumnImage col={{ xs: 1, sm: 1, md: 1, lg: 1 / 2 }} p={{ xs: 10, md: 27.5 }} className="column-image">
                <StyledImage fluid={image} alt={alt}/>                
              </ColumnImage>
            </Box>
          </TopContainer>
          <BottomWrapper>
            <InfoCardWrapper>
              <Container>
                <InfoCardInnerWrapper>
                  <Box row mx={-27.5} my={{ xs: -10, lg: 0 }} justifyContent="center">
                    <Box
                      key={items[0].entity.entityTranslation.uuid}
                      col={{ xs: 1, sm: 1, md: 1, lg: 2 / 3 }}
                      px={27.5} py={{ xs: 10, lg: 0 }}
                      alignItems="stretch">
                    <InfoCard
                      title={items[0].entity.entityTranslation.fieldTitle}
                      linkText={items[0].entity.entityTranslation.fieldLinkText}
                      index={0}
                      />
                    </Box>
                  </Box>
                </InfoCardInnerWrapper>
              </Container>
            </InfoCardWrapper>
          </BottomWrapper>
        </InnerWrapper>
      </VisibilitySensor>
    </StyledSection>
  )
}

export default SectionInfoCards

SectionInfoCards.defaultProps = {
  className: 'section',
  id: ''
}

SectionInfoCards.propTypes ={
  className: PropTypes.string,
  id: PropTypes.string
}