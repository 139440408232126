import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled, { css } from  '@xstyled/styled-components'

import IconArrowRight from '../svgs/icons/icon-arrow-right.svg'
import IconArrowUpRight from '../svgs/icons/icon-arrow-up-right.svg'

const LinkStyle = css`
  display: inline-block;
  font-size: 18rpx;
  line-height: 21rpx;
  color: var(--ssy-orange);
  font-weight: 800;
  text-decoration: none;
  /* transition: color 0.5s ease-in-out; */

  .inner-wrapper {
    display: inline-block;
    padding-bottom: 4rpx;
    border-bottom: 2px solid var(--ssy-orange);
  }

  .icon-arrow-right {
    position: relative;
    width: 15rpx;
    height: auto;
    top: 3rpx;
    left: 8rpx;

    g {
      fill: var(--ssy-orange);
      /* transition: fill 0.5s ease-in-out; */
    }
  }

  .icon-arrow-up-right {
    position: relative;
    width: 13.65rpx;
    height: auto;
    top: 8rpx;
    left: 8rpx;

    g > g {
      fill: var(--ssy-orange);
      /* transition: fill 0.5s ease-in-out; */
    }
  }

  &:hover {
    color: var(--ssy-black);

    .inner-wrapper {
      border-color: transparent;
    }

    .icon-arrow-right {
      g {
        fill: var(--ssy-black);
      }
    }

    .icon-arrow-up-right {
      g > g {
        fill: var(--ssy-black);
      }
    }
  }
`

const InlineStyles = css`
  display: inline;
  line-height: 30rpx;

  .inner-wrapper,
  svg {
    display: inline;
  }
`
const StyledGatsbyLink = styled(Link)`
  ${LinkStyle}
  ${p => p.inline && css`
  display: inline;

    .inner-wrapper,
    svg {
      display: inline;
    }
  
  `
  }
`
const StyledExtLink = styled.a`
  ${LinkStyle}
  ${p => p.inline && InlineStyles}
`

const StaticLink = styled.div`
  ${LinkStyle}
  ${p => p.inline && InlineStyles}
`

const LinkIcon = ({ staticLink, iconUpArrow, text, url, urlExt, inline}) => {
  if (url) {
    return (
      <StyledGatsbyLink to={url} inline={inline} className="link-icon">
        <div className="inner-wrapper">{text}</div>
        {iconUpArrow ? (<IconArrowUpRight />) : (<IconArrowRight />)}
      </StyledGatsbyLink>
    )
  }
  if (urlExt) {
    return (
      <StyledExtLink href={urlExt} inline={inline} className="link-icon">
        <div className="inner-wrapper">{text}</div>
        {iconUpArrow ? (<IconArrowUpRight />) : (<IconArrowRight />)}
      </StyledExtLink>
    )
  }
  if (staticLink) {
    return (
      <StaticLink inline={inline} className="link-icon">
        <div className="inner-wrapper">{text}</div>
        {iconUpArrow ? (<IconArrowUpRight />) : (<IconArrowRight />)}
      </StaticLink>
    )
  }
  
}

export default LinkIcon

LinkIcon.defaultProps = {
 staticLink: false
}

LinkIcon.propTypes = {
  staticLink: PropTypes.bool
}