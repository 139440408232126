import React from 'react'
import styled, { css, up } from '@xstyled/styled-components'

import IconPriorityCircle from  '../../images/icon-priority-circle.svg'
import parse from 'html-react-parser'

const Priority = styled.div`
  position: absolute;
  width: calc(100vw - 60px);
  padding: 30rpx;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 97;

  ${up(
    'lg',
    css`
      width: calc(42.3vw - 120px);
      padding: 0 60rpx;
    `
  )}

  ${up(
    'xl',
    css`
      max-width: 502.91rpx;
    `
  )}
`

const Title = styled.div`
  font-size: 18rpx;
  line-height: 24rpx;
  font-weight: 800;
  color: var(--ssy-white);
  text-align: center;

  ${up(
    'lg',
    css`
      font-size: 36rpx;
      line-height: 42rpx;
      margin-bottom: 24rpx;
    `
  )}
`

const Summary = styled.div`
  display: none;
  font-size: 14rpx;
  line-height: 20rpx;
  color: var(--ssy-white);
  text-align: center;

  ${up(
  'lg',
    css`
      display: block;
      font-size: 18rpx;
      line-height: 30rpx;
    `
)}
`

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35rpx;
  height: 35rpx;
  margin: 0 auto 10rpx;
  background-image: url(${IconPriorityCircle});
  background-size: 35px 35px;
  background-repeat: no-repeat;
  font-size: 18rpx;
  line-height: 21rpx;
  font-weight: 800;
  color: var(--ssy-white);

  ${up(
    'lg',
    css`
      width: 110rpx;
      height: 110rpx;
      background-size: 110px 110px;
      margin: 0 auto 30rpx;
      font-size: 48rpx;
      line-height: 56rpx;
    `
  )}
`

const PriorityTitle = ({ title, summary, index, lang }) => {
  const number = index + 1
  return (
    <Priority className={`priority priority-${index}-${lang}`}>
      <div className="test-height">
      <Circle>{number}</Circle>
      <Title>{title}</Title>
      <Summary>{parse(summary)}</Summary>
      </div>
    </Priority>
  )
}

export default PriorityTitle
